import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import useLocalStorage from 'use-local-storage';
import { initReactI18next } from "react-i18next";

// functions
import { StripDoubleQuotes } from './functions';
	
//	-------------------------------------------------
//
//	Chooses between a localhost path and production service path,
//	depending up what is currently running.
//
//	The process.env.NODE_ENV can have values: "development", "production" or "test"
//
//	-------------------------------------------------

const getCurrentHost = process.env.NODE_ENV === "development"
					? "http://localhost:3000"
					: "https://gateway.srcdev.skao.int";

i18n
	.use(i18nBackend)
	.use(initReactI18next)
	.init(	{
		fallbackLng: "en",
		lng: StripDoubleQuotes( { value: localStorage.getItem( 'gateway_language' ) === null ? 'en' : localStorage.getItem( 'gateway_language' ) } ),
		interpolation:	{
				escapeValue: false,
				},
		backend:	{
				loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
				},
		} );

export default i18n;
