import React from 'react';
import '../App.css';
import './search-compute.css';
import CSS from 'csstype';
import { APIPrefix } from '../functions';
import { AccessToken } from '../types';
import { withTranslation, WithTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface SiteListProps extends WithTranslation
{

	changeSite: any;
	placeholderShown: boolean;

} // SiteListProps

interface SiteListState
{

	siteList: string[];

} // SiteListState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

class SearchComputeSiteList extends React.Component<SiteListProps, SiteListState>
{

	constructor( props:SiteListProps )
	{
		super( props );
		this.state =
		{
			siteList: []
		};
	};

	async componentDidMount()
	{
							
		var urlCommand: string = APIPrefix() + '/v1/site_capabilities/list_sites';

		try
		{
		
			const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
			
			console.log( "search-compute-site-list => componentDidMount => apiResult.status:" );
			console.log( apiResult );

			// Return code 200 means the API has run.
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get services list.
				var siteList: any = [];
				if (returnedJson.sites !== undefined)
					siteList = returnedJson.sites;

				// update the state with the list of returned sites.
				this.setState( {siteList: siteList} );
			
			}
			
		}
		catch (e)
		{
		}
		
	} // componentDidMount

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		this.props.changeSite( {site: value} );
				
	} // onChange
	
	render()
	{
	
		{/* main search form */}
		return	(
		    	
		    	<select	name = "lstSite"
		    			className = "site-listbox"
		    			multiple = {false}
		    			value = ""
		    			onChange = {this.onChange}
		    			data-placeholder-shown = {this.props.placeholderShown ? "T" : "F"}>
		    		<option hidden value = "">{this.props.t("Select site")}</option>
		    		<option label = "All" value = "all"> All </option>
		    		{
		    			this.state.siteList.map
		    			(
		    				item =>
		    				(
		    					<option	key = {item}
		    							label = {item}
		    							value = {item}> {item} </option>
		    				)
		    			)
		    		}
			</select> 
			
			)
			
	}

} // SearchComputeSiteList

export default withTranslation() (SearchComputeSiteList);
