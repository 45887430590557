import React, { useState, useEffect } from 'react';
import '../App.css';
import './search-compute.css';
import CSS from 'csstype';
import '../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../icons/gears.gif';
import siteIcon from '../icons/site.512.png';
import jupyterHUBIcon from '../icons/jupyterHUB.512.png';
import canfarIcon from '../icons/canfar.512.png';
import azimuthDarkIcon from '../icons/azimuth-dark.512.png';
import azimuthLightIcon from '../icons/azimuth-light.512.png';
import playIcon from '../icons/play-square.512.png';
import playIconDisabled from '../icons/play-square-bw.512.png';
import infoIcon from '../icons/info-square.512.png';

// types
import { AccessToken } from '../types';

// types relating to tasks.
import { TaskType } from '../tasks';
import { UserSettings } from '../tasks';
import { UserServiceToken } from '../tasks';
import { CurrentTask } from '../tasks';

// functions
import { APIPrefix } from '../functions';

// classes
import ToolButton from '../tools/tool-button';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface UserToken
{

	siteIndex: number;
	serviceIndex: number;
	serviceID: string;
	savedValues: {usingToken: boolean, username: string, token: string};
	editedValues: {usingToken: boolean, username: string, token: string};
	editing: boolean;

} // UserToken

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------
	
function ServiceInfo( args:	{
				item: any,
				siteIndex: number,
				serviceIndex: number,
				findUserToken: any,
				serviceInfo: {siteIndex: number, serviceIndex: number},
				renderCount: number,
				inputHandler: any,
				onCheckBoxChange: any,
				onClickHandler: any
				} )
{

  	const TABLE_COL_SMALL_1: CSS.Properties =
  			{
  			padding: '2px 10px 2px 10px',
  			border: 'none',
  			fontSize: '11pt'
  			}
  	const TABLE_COL_SMALL_2: CSS.Properties =
  			{
  			padding: '2px 10px 2px 10px',
  			border: 'none',
  			fontSize: '11pt',
  			wordWrap: 'break-word',
  			overflowWrap: 'break-word'
  			}
  	const TABLE_TEXT_SMALL: CSS.Properties =
  			{
  			fontSize: '11pt'
  			}

	// get some values from the service item.
	const serviceID: string = ('id' in args.item ? args.item[ 'id' ] : '');
	const prefix: string = ('prefix' in args.item ? args.item[ 'prefix' ] : '');
	const host: string = ('host' in args.item ? args.item[ 'host' ] : '');
	const port: string = ('port' in args.item ? args.item[ 'port' ] : '');
	const path: string = ('path' in args.item ? args.item[ 'path' ] : '');
	
	// build an ID string.
	const siteServiceID = args.siteIndex.toString() + '_' + args.serviceIndex.toString();
  			
  	// find the correct user token for this site and service index.
	const userToken: UserToken = args.findUserToken( {siteIndex: args.siteIndex, serviceIndex: args.serviceIndex} )
	
	// get some token state values.
	var tokenIsSupplied: boolean = (userToken.editing === true ? userToken.editedValues.usingToken : userToken.savedValues.usingToken);
	var tokenIsBeingEdited: boolean = (userToken.editing === true);
	var tokenIsSuppliedAndBeingEdited: boolean = (userToken.editing == true && userToken.editedValues.usingToken == true);
	var username: string = (userToken.editing === true ? userToken.editedValues.username : userToken.savedValues.username);
	var token: string = (userToken.editing === true ? userToken.editedValues.token : userToken.savedValues.token);
	
	// is the service info displayed for this service?
	const serviceInfoDisplayed: boolean = args.serviceInfo.siteIndex === args.siteIndex &&
						args.serviceInfo.serviceIndex === args.serviceIndex;

	return	(
		<div	className = "search-compute-service-info"
			data-info-displayed = {serviceInfoDisplayed === true ? "Y" : "N"}>
			<table className = "search-compute-table">
				<tr>	<td style = {TABLE_COL_SMALL_1}>ID:</td>
					<td style = {TABLE_COL_SMALL_2} colSpan = {3}>{serviceID}</td></tr>
				<tr>	<td style = {TABLE_COL_SMALL_1}>Url:</td>
					<td style = {TABLE_COL_SMALL_2} colSpan = {3}>{prefix + '://' + host + (port !== '' ? ':' : '') +
											    				port + path}</td></tr>
				<tr>	<td style = {TABLE_COL_SMALL_2} colSpan = {4}>
						<input	type = 'checkbox' id = {'chkUserToken_' + siteServiceID}
							checked = {tokenIsSupplied === true}
							onChange = {args.onCheckBoxChange}
							disabled = {tokenIsBeingEdited === false} />
						<label	style = {TABLE_TEXT_SMALL}
							htmlFor = {'chkUserToken_' + siteServiceID}>Enable user token ?</label>
					</td></tr>
				<tr>	<td style = {TABLE_COL_SMALL_2} colSpan = {4}>
						<input	className = "search-compute-table-input-box"
							id = {'inpUsername_' + siteServiceID}
							onChange = {args.inputHandler}
							placeholder = "Username"
							value = {username}
							disabled = {tokenIsSuppliedAndBeingEdited === false}
							data-disabled = {tokenIsSuppliedAndBeingEdited === false ? "T" : "F"} />
					</td></tr>
				<tr>	<td style = {TABLE_COL_SMALL_2} colSpan = {4}>
						<input	className = "search-compute-table-input-box"
							id = {'inpToken_' + siteServiceID}
							onChange = {args.inputHandler}
							placeholder = "Token"
							value = {token}
							disabled = {tokenIsSuppliedAndBeingEdited === false}
							data-disabled = {tokenIsSuppliedAndBeingEdited === false ? "T" : "F"} />
					</td></tr>
				<tr>	<td style = {TABLE_COL_SMALL_2} colSpan = {4}>
						<div className = "search-compute-table-button-holder">
							<ToolButton	key = {args.renderCount}
									name = {"cmdEditToken_" + siteServiceID}
									onClick = {args.onClickHandler}
									text = "Edit"
									disabled = {tokenIsBeingEdited === true} />
						</div>&nbsp;
						<div className = "search-compute-table-button-holder">
							<ToolButton	key = {args.renderCount}
									name = {"cmdSaveToken_" + siteServiceID}
									onClick = {args.onClickHandler}
									text = "Save"
									disabled = {tokenIsBeingEdited === false} />
						</div>&nbsp;
						<div className = "search-compute-table-button-holder">
							<ToolButton	key = {args.renderCount}
									name = {"cmdCancelToken_" + siteServiceID}
									onClick = {args.onClickHandler}
									text = "Cancel"
									disabled = {tokenIsBeingEdited === false} />
						</div>
					</td></tr>
			</table>
		</div>
		)

} // ServiceInfo
	
function Icon( args: { item: any } )
{
	
	if ('type' in args.item)
	{
		const serviceType: string = args.item[ 'type' ].toUpperCase();
		switch (serviceType)
		{
			case "JUPYTERHUB":
				return	(
					<img src = {jupyterHUBIcon} alt = "" width = "120" />
					)
				break;
			case "CANFAR":
				return	(
					<img src = {canfarIcon} alt = "" width = "120" />
					)
				break;
			case "AZIMUTH":
				return	(
					<img src = {azimuthLightIcon} alt = "" width = "120" />
					)
				break;
			default:
				return	(
					<div className = "search-compute-service-icon-empty">{serviceType}</div>
					)
				break;
		}
	}
	else
		return	(
			<div className = "search-compute-service-icon-empty"></div>
			)
			
} // Icon

function Buttons( args: { item: any, siteIndex: number, serviceIndex: number, onClickImgHandler: any } )
{

	return	(
			<div className = "search-compute-service-buttons">
				<img className = "search-compute-service-icon" src = {'type' in args.item ? (args.item['type'].toUpperCase() === 'JUPYTERHUB' || args.item['type'].toUpperCase() === 'CANFAR' || args.item['type'].toUpperCase() === 'AZIMUTH' ? playIcon : playIconDisabled) : playIconDisabled}
					id = {'startService_' + args.siteIndex.toString() + '_' + args.serviceIndex.toString()} onClick = {args.onClickImgHandler} alt = "" width = "36" />
				<img className = "search-compute-service-icon" src = {infoIcon} id = {'infoService_' + args.siteIndex.toString() + '_' + args.serviceIndex.toString()}
					onClick = {args.onClickImgHandler} alt = "" width = "36" />
			</div>
		)

} // Buttons

//	------------------------------------------------------------
//
//	Display an icon, description, and a launch button for
//	each item in a list of services.
//
//	------------------------------------------------------------

function Services( args:	{
				siteIndex: number,
				associatedServices: any[],
				onClickImgHandler: any,
				findUserToken: any,
				serviceInfo: {siteIndex: number, serviceIndex: number},
				renderCount: number,
				inputHandler: any,
				onCheckBoxChange: any,
				onClickHandler: any
				} )
{

	return	(
		<div className = "search-compute-services">
		{
			args.associatedServices.map( (item, index) =>
			(
				<div className = "search-compute-service">
				
					<div className = "search-compute-service-main">
						<div className = "flex-10px"></div>
						<Icon item = {item} />
						<Buttons	item = {item}
								siteIndex = {args.siteIndex}
								serviceIndex = {index}
								onClickImgHandler = {args.onClickImgHandler} />
						<div className = "search-compute-service-details">
							<div className = "search-compute-service-name">
								{'identifier' in item ? item[ 'identifier' ] : ''}
							</div>
						</div>
					</div>
					
					<ServiceInfo	item = {item}
							siteIndex = {args.siteIndex}
							serviceIndex = {index}
							findUserToken = {args.findUserToken}
							serviceInfo = {args.serviceInfo}
							renderCount = {args.renderCount}
							inputHandler = {args.inputHandler}
							onCheckBoxChange = {args.onCheckBoxChange}
							onClickHandler = {args.onClickHandler} />
					
				</div>
			) )
		}
		</div>
		)

} // Services

//	------------------------------------------------------------
//
//	Display a site, occupying the whole width of the flex box,
//	with a list of services underneath it.
//
//	------------------------------------------------------------

function Site( args:	{
			siteIndex: number,
			row: any,
			findUserToken: any,
			serviceInfo: {siteIndex: number, serviceIndex: number},
			renderCount: number,
			inputHandler: any,
			onCheckBoxChange: any,
			onClickHandler: any,
			onClickImgHandler: any
			} )
{

	// translation function
	const { t } = useTranslation();

	// class-level constants.
  	const TABLE_COL: CSS.Properties =
  			{
  			padding: '0px 10px 0px 10px',
  			border: 'none',
  			fontSize: '12pt'
  			}

	return	(
		<>
			<div	className = "search-compute-site"
				data-type = "container">
				<div	className = "search-compute-site"
					data-type="opacity"></div>
				<div	className = "search-compute-site"
					data-type = "flexbox">
					<div className = "search-compute-site-icon">
						<img	src = {siteIcon}
							alt = ""
							width = "60"
							height = "60" />
						<div className = "search-compute-site-sitename">
							{'site' in args.row ? args.row[ 'site' ] : ''}
						</div>
					</div>
					{/*<div className="search-compute-site-buttons">
						<img className="search-compute-site-button" src={infoIcon} alt="" width="40" />
					</div>*/}
					<div className = "search-compute-site-details">
						<div className = "search-compute-site-details-section">
							<table>
								<tr>	<td style = {TABLE_COL}>{t('Description')}:</td>
									<td style = {TABLE_COL} colSpan = {3}>{'description' in args.row ? args.row[ 'description' ] : ''}</td></tr>
								<tr>	<td style = {TABLE_COL}>{t('Location')}:</td>
									<td style = {TABLE_COL}>{'latitude' in args.row ? args.row[ 'latitude' ] : '/'} &deg; N, {'longitude' in args.row ? args.row[ 'longitude' ] : '/'} &deg; E</td>
									<td style = {TABLE_COL}>{t('Hardware type')}:</td>
									<td style = {TABLE_COL}>{'hardware_type' in args.row ? args.row[ 'hardware_type' ] : ''}</td>
									{/*<td style = {TABLE_COL}>Middleware version:</td>
									<td style = {TABLE_COL}>{'middleware_version' in args.row ? args.row[ 'middleware_version' ] : ''}</td>*/}</tr>
								<tr>	<td style = {TABLE_COL}>{t('Hardware capabilities')}:</td>
									<td style = {TABLE_COL} colSpan = {3}>{'hardware_capabilities' in args.row ? args.row[ 'hardware_capabilities' ] : ''}</td></tr>
							</table>
						</div>
						<div className = "flex-expanding" ></div>
					</div>
				</div>
			</div>
			{
				'associated_services' in args.row ? Services(	{
										siteIndex: args.siteIndex,
										associatedServices: args.row[ 'associated_services' ],
										onClickImgHandler: args.onClickImgHandler,
										findUserToken: args.findUserToken,
										serviceInfo: args.serviceInfo,
										renderCount: args.renderCount,
										inputHandler: args.inputHandler,
										onCheckBoxChange: args.onCheckBoxChange,
										onClickHandler: args.onClickHandler
										} ) : <></>
			}
		</>
		)

} // Site

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function SearchComputeTable( props:	{
							key: string,
							tableDisplayed: boolean,
							site: string,
							gpu: boolean,
							largeScratch: boolean,
							highMemory: boolean,
							fastScratch: boolean,
							description: string,
							hardwareType: string,
							serviceType: string,
							middlewareVersion: string,
							userLatitude: number | undefined,
							userLongitude: number | undefined,
							taskExecutor: any,
							renewTokens: any,
							launchNotebook: any
							} )
{

	// translation function
	const { t } = useTranslation();
  			
  	// state variables.
	const [sServicesList, setServicesList] = useState< any[] >( [] );
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( true );
	const [sServiceInfo, setServiceInfo] = useState< {siteIndex: number, serviceIndex: number} >( { siteIndex: -1, serviceIndex: -1 } );
	const [sUserTokens, setUserTokens] = useState< UserToken[] >( [] );
	const [sRenderCount, setRenderCount] = useState< number >( 0 );

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the page has loaded.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  	
		  		// load the data from the compute end point of the site-capabilities API.
		  		loadComputeData();
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	for each site, calculate the distance from the site to the user's
	//	latitude and longitude. Add the result to the list of information
	//	stored about each site
	//
	//	------------------------------------------------------------
	
	function addDistances( args: { siteList: object[] } )
	{
	
		// loop over sites.
		for ( var i = 0; i < args.siteList.length; i++ )
		{
		
			var site: {} = args.siteList[ i ];
			var distance: number | undefined = undefined;
			if (props.userLatitude != undefined && props.userLongitude != undefined && 'latitude' in site && 'longitude' in site)
				try
				{
				
					var siteLat: number = Number( site[ 'latitude' ] );
					var siteLong: number = Number( site[ 'longitude' ] );
				
					// find separation in radians
					let rLat = (siteLat - props.userLatitude) * (Math.PI / 180);
					let rLong = (siteLong - props.userLongitude) * (Math.PI / 180);
					let a = Math.pow( Math.sin( rLat / 2 ), 2 ) + Math.cos( siteLat * (Math.PI / 180) ) *
								Math.cos( props.userLatitude * (Math.PI / 180) ) * Math.pow( Math.sin( rLong / 2 ), 2 );
					
					// find separation on a sphere of unit radius.
					distance = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
				
				}
				catch(e)
				{
					console.log( "Error calculating distance from user to site" );
				}
				
			// append this distance measurement to the site object.
			Object.assign( site, { distance: distance } );
			
		}
		
	} // addDistances

	//	------------------------------------------------------------
	//
	//	Look through the array in state for a record of the
	//	requested user token. If we don't find a record, we add
	//	one.
	//
	//	------------------------------------------------------------
	
	function findUserToken( args: { siteIndex: number, serviceIndex: number, userTokens?: UserToken[] } )
	{
	
		var userTokens: UserToken[] = sUserTokens;
		var item: UserToken =	{
				  	siteIndex: -1,
				  	serviceIndex: -1,
				  	serviceID: '',
				  	savedValues: {usingToken: false, username: '', token: ''},
				  	editedValues: {usingToken: false, username: '', token: ''},
				  	editing: false
					};
		
		// use the supplied array instead maybe.
		if (args.userTokens !== undefined)
			userTokens = args.userTokens;
		
  		// find this token in the array, and set the edit flag to true.
  		let index: number = userTokens.findIndex( element => (element.siteIndex === args.siteIndex && element.serviceIndex === args.serviceIndex) );
  		if (index > -1)
  			item = userTokens[ index ];
  		else if (args.userTokens !== undefined)
  		{

  			// get the service ID from the state.
  			var serviceID: string = '';
  			const site: any = sServicesList[ args.siteIndex ];
  			if ('associated_services' in site)
  			{
  				const services: any[] = site[ 'associated_services' ];
  				const service: any = services[ args.serviceIndex ];
  				if ('id' in service)
  					serviceID = service[ 'id' ];
  			}
  		
  			// we must add the token first.create a copy of the state.
  			item =	{
  				siteIndex: args.siteIndex,
  				serviceIndex: args.serviceIndex,
  				serviceID: serviceID,
  				savedValues: {usingToken: false, username: '', token: ''},
  				editedValues: {usingToken: false, username: '', token: ''},
  				editing: true
  				};
  			userTokens.push( item );
  		
  		}
  		
  		// return something.
  		return item;
	
	} // findUserToken

	//	------------------------------------------------------------
	//
	//	Form controls are often post-fixed with A_B, where A is the
	//	site index and B is the service index. Here we split the
	//	ID and convert to numeric.
	//
	//	------------------------------------------------------------
	
	function getSiteAndServiceIndexes( args: { serviceID: string } )
	{
  			
		// get the site index and service index.
		var siteIndex: string = '';
		var serviceIndex: string = '';
		if (args.serviceID.indexOf( '_' ) > -1)
		{
			siteIndex = args.serviceID.slice( 0, args.serviceID.indexOf( '_' ) );
			serviceIndex = args.serviceID.slice( args.serviceID.indexOf( '_' ) + 1, args.serviceID.length );
		}
		
		let siteIndexNumeric: number = -1;
		let serviceIndexNumeric: number = -1;
		try
		{
			siteIndexNumeric = Number( siteIndex );
			serviceIndexNumeric = Number( serviceIndex );
		}
		catch (e)
		{
		}
		
		// return something.
		return { siteIndex: siteIndexNumeric, serviceIndex: serviceIndexNumeric };
	
	} // getSiteAndServiceIndexes

	//	------------------------------------------------------------
	//
	//	Get a list of user-access tokens from the back-end API
	//
	//	------------------------------------------------------------
	
	async function getUserTokens( args: { servicesList: any[] } )
	{

		try
		{

  			// call the /get_user_tokens endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/get_user_tokens',
								{
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();
				
				var userTokens: UserToken[] = [];

				// loop through the returned records.
				if ('user_tokens' in returnedJson)
				{
				
					var returnedData: { username: string, token: string, service_id: string }[] = returnedJson[ 'user_tokens'];
					if (returnedData.length > 0)
					{
					
						for ( let i = 0; i < returnedData.length; i++ )
						{

							var item: { username: string, token: string, service_id: string } = returnedData[ i ];
							
							// add a record to the list of tokens we hold.
							const newToken: UserToken =	{
											siteIndex: -1,
											serviceIndex: -1,
											serviceID: item['service_id'],
											savedValues: {usingToken: true, username: item['username'], token: item['token']},
											editedValues: {usingToken: true, username: item['username'], token: item['token']},
											editing: false};
							userTokens.push( newToken );

							// get the site and service index for this service id.
							var siteIndex: number = -1;
							var serviceIndex: number = -1;
							for ( var siteLoop = 0; siteLoop < args.servicesList.length; siteLoop++ )
							{
								var site: any = args.servicesList[ siteLoop ];
								if ('associated_services' in site)
								{
									var associated_services: any = site[ 'associated_services' ];
									for ( var serviceLoop = 0; serviceLoop < associated_services.length; serviceLoop++ )
									{
										var service: any = associated_services[ serviceLoop ];
										if ('id' in service)
											if (service['id'] === newToken.serviceID)
											{
												siteIndex = siteLoop;
												serviceIndex = serviceLoop;
											}
									}
								}
							}
							newToken.siteIndex = siteIndex;
							newToken.serviceIndex = serviceIndex;
							
						}
		  			
			  			// update the state.
			  			const renderCount = sRenderCount + 1;
			  			setUserTokens( userTokens );
			  			setRenderCount( renderCount );
			  			
			  		}
			  		
			  	}
				
			}
		
      		}
      		catch (e)
      		{
		}
	
	} // getUserTokens

	//	------------------------------------------------------------
	//
	//	Handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;

  		// if we've changed the user token username box, then update the state.
  		if (inputBox.id.length > 12)
  			if (inputBox.id.slice( 0, 12 ) === 'inpUsername_')
  			{
  			
  				// get site and service indexes.
				const index: { siteIndex: number, serviceIndex: number } =
									getSiteAndServiceIndexes( { serviceID: inputBox.id.slice( 12 - inputBox.id.length ) } );
				
				if (index.siteIndex > -1 && index.serviceIndex > -1)
				{
				
		  			// create a copy of the state.
		  			var cpUserTokens: UserToken[] = sUserTokens.slice();
		  			
		  			// find the user token, and add it if it doesn't exist.
		  			var item: UserToken = findUserToken( { siteIndex: index.siteIndex, serviceIndex: index.serviceIndex, userTokens: cpUserTokens } );
			  		
			  		// update check box and state.
			  		item.editedValues.username = inputBox.value;
		  			setUserTokens( cpUserTokens );
				
				}
				
			}

  		// if we've changed the user token box, then update the state.
  		if (inputBox.id.length > 9)
  			if (inputBox.id.slice( 0, 9 ) === 'inpToken_')
  			{
  			
  				// get site and service indexes.
				const index: { siteIndex: number, serviceIndex: number } =
									getSiteAndServiceIndexes( { serviceID: inputBox.id.slice( 9 - inputBox.id.length ) } );
				
				if (index.siteIndex > -1 && index.serviceIndex > -1)
				{
				
		  			// create a copy of the state.
		  			var cpUserTokens: UserToken[] = sUserTokens.slice();
		  			
		  			// find the user token, and add it if it doesn't exist.
		  			var item: UserToken = findUserToken( { siteIndex: index.siteIndex, serviceIndex: index.serviceIndex, userTokens: cpUserTokens } );
			  		
			  		// update check box and state.
			  		item.editedValues.token = inputBox.value;
		  			setUserTokens( cpUserTokens );
				
				}
				
			}
  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	An asynchronous function that resolves a name against
	//	an online database, such as NED, SIMBAD or Sesame.
	//
	//	------------------------------------------------------------
  	
  	async function loadComputeData()
  	{
	
		try
		{

			var urlCommand: string = APIPrefix() + '/v1/site_capabilities/list_compute';
			
			var params: string = '';
			
			// add site.
			if (props.site !== '' && props.site !== 'all')
				params = params +
							'&site=' + encodeURIComponent( props.site );
							
			// add description.
			if (props.description !== '')
				params = params +
							'&description=' + encodeURIComponent( props.description );
							
			// add hardware capabilities.
			if (props.gpu == true)
				params = params + '&gpu=T';
			if (props.largeScratch == true)
				params = params + '&large_scratch=T';
			if (props.highMemory == true)
				params = params + '&high_memory=T';
			if (props.fastScratch == true)
				params = params + '&fast_scratch=T';
						
			// add hardware type.
			if (props.hardwareType !== '' && props.hardwareType !== 'any')
				params = params +
							'&hardware_type=' + encodeURIComponent( props.hardwareType );

			// add service type
			if (props.serviceType !== '' && props.serviceType !== 'all')
				params = params + '&service_type=' + encodeURIComponent( props.serviceType );
							
			// add middleware version.
			if (props.middlewareVersion !== '')
				params = params +
							'&middleware_version=' + encodeURIComponent( props.middlewareVersion );
							
			if (params !== '')
				urlCommand = urlCommand + '&' + params.substr( 1 );

			try
			{
				
				const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
				if (apiResult.status === 200)
				{
				
					const returnedJson = await apiResult.json();

					// get services list.
					var servicesList: object[] = [];
					if (returnedJson.compute !== undefined)
						servicesList = returnedJson.compute;
						
					// compute the distance of each site from the requested position, and sort the results by distance.
					addDistances( { siteList: servicesList } );
					sortByDistance( { array: servicesList, low: 0, high: servicesList.length - 1 } );

					// collect the user service access tokens.
					getUserTokens( { servicesList: servicesList } );
					
					// update the state with the list of returned site services.
					setServicesList( servicesList );
					setLoadingComponent( false );
					
				}
				
				// if the return code is 401 then either the site-capabilities token or the gateway-backend
				// token has expired. we should renew them.
				if (apiResult.status === 401)
					props.renewTokens( {} );
				
			}
			catch (e)
			{
				console.log( e );
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // loadComputeData

	//	------------------------------------------------------------
	//
	//	Fires when one of the check boxes is changed.
	//
	//	------------------------------------------------------------
	
	const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
	{
  	
  		const checkBox: HTMLInputElement = event.currentTarget;

  		// if we've clicked on the Enable User Token checkbox, then update the setting in the state.
  		if (checkBox.id.length > 13)
  			if (checkBox.id.slice( 0, 13 ) === 'chkUserToken_')
  			{
  			
  				// get site and service indexes.
				const index: { siteIndex: number, serviceIndex: number } =
									getSiteAndServiceIndexes( { serviceID: checkBox.id.slice( 13 - checkBox.id.length ) } );
				if (index.siteIndex > -1 && index.serviceIndex > -1)
				{
				
		  			// create a copy of the state.
		  			var cpUserTokens: UserToken[] = sUserTokens.slice();
		  			
		  			// find the user token, and add it if it doesn't exist.
		  			var item: UserToken = findUserToken( { siteIndex: index.siteIndex, serviceIndex: index.serviceIndex, userTokens: cpUserTokens } );
			  		
			  		// update check box and state.
			  		item.editedValues.usingToken = checkBox.checked;
	  			
		  			// update the state.
		  			const renderCount = sRenderCount + 1;
		  			setUserTokens( cpUserTokens );
		  			setRenderCount( renderCount );
				
				}
				
  			}
	
	} // onCheckBoxChange

	//	------------------------------------------------------------
	//
	//	Handler for button clicks
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const button: HTMLInputElement = event.currentTarget;
  		
  		// set to setID_serviceID for instances where we are handling user token button clicks.
  		var userTokenID: string = '';
  		
  		// if we've clicked to edit a user token then get the rest of the identifier.
  		if (button.name.length > 13)
  			if (button.name.slice( 0, 13 ) === "cmdEditToken_")
				userTokenID = button.name.slice( 13 - button.name.length );
  		
  		// if we've clicked to save a token then get the rest of the identifier.
  		if (button.name.length > 13)
  			if (button.name.slice( 0, 13 ) === "cmdSaveToken_")
				userTokenID = button.name.slice( 13 - button.name.length );
  		
  		// if we've clicked to cancel updating a token then get the rest of the identifier.
  		if (button.name.length > 15)
  			if (button.name.slice( 0, 15 ) === "cmdCancelToken_")
				userTokenID = button.name.slice( 15 - button.name.length );
				
		// if we've clicked to edit, save, or cancel editing a user token then get the list of tokens.
		var cpUserTokens: UserToken[] = [];
		var item: UserToken =	{
				  	siteIndex: -1,
				  	serviceIndex: -1,
				  	serviceID: '',
				  	savedValues: {usingToken: false, username: '', token: ''},
				  	editedValues: {usingToken: false, username: '', token: ''},
				  	editing: false
				  	};
		var index: { siteIndex: number, serviceIndex: number } = { siteIndex: -1, serviceIndex: -1 };
		if (userTokenID !== '')
		{
  			
			// get site and service indexes.
			index = getSiteAndServiceIndexes( { serviceID: userTokenID } );
			if (index.siteIndex > -1 && index.serviceIndex > -1)
			{
			
	  			// create a copy of the state.
	  			cpUserTokens = sUserTokens.slice();
		  			
	  			// find the user token, and add it if it doesn't exist.
	  			item = findUserToken( { siteIndex: index.siteIndex, serviceIndex: index.serviceIndex, userTokens: cpUserTokens } );
			
			}
			
		}
  		
  		// if we've clicked to edit a user token then get the rest of the identifier.
  		if (button.name.length > 13 && item.siteIndex > -1 && item.serviceIndex > -1)
  			if (button.name.slice( 0, 13 ) === "cmdEditToken_")
  			{
			  			
	  			item.editing = true;
	  			
	  			// update the state.
	  			const renderCount = sRenderCount + 1;
	  			setUserTokens( cpUserTokens );
	  			setRenderCount( renderCount );
				
  			}
  		
  		// if we've clicked to save a token then update the state.
  		if (button.name.length > 13 && item.siteIndex > -1 && item.serviceIndex > -1)
  			if (button.name.slice( 0, 13 ) === "cmdSaveToken_")
  			{
			  			
	  			item.editing = false;
	  			item.savedValues.usingToken = item.editedValues.usingToken;
	  			item.savedValues.username = item.editedValues.username;
	  			item.savedValues.token = item.editedValues.token;
	  			
	  			// update the token values using the API.
	  			const userServiceToken: UserServiceToken =	{
			  							serviceID: item.serviceID,
			  							usingToken: item.editedValues.usingToken,
			  							username: item.editedValues.username,
			  							userToken: item.editedValues.token
			  							};
			  	const currentTask: CurrentTask =	{
									taskType: TaskType.UPDATE_USER_SERVICE_TOKEN,
									userServiceToken: userServiceToken
									}
	  			props.taskExecutor( { currentTask: currentTask } );
	  			
	  			// update the state.
	  			const renderCount = sRenderCount + 1;
	  			setUserTokens( cpUserTokens );
	  			setRenderCount( renderCount );
				
  			}
  		
  		// if we've clicked to cancel updating a token then update the state.
  		if (button.name.length > 15 && item.siteIndex > -1 && item.serviceIndex > -1)
  			if (button.name.slice( 0, 15 ) === "cmdCancelToken_")
  			{
			  			
	  			item.editing = false;
	  			item.editedValues.usingToken = item.savedValues.usingToken;
	  			item.editedValues.username = item.savedValues.username;
	  			item.editedValues.token = item.savedValues.token;
	  			
	  			// update the state.
	  			const renderCount = sRenderCount + 1;
	  			setUserTokens( cpUserTokens );
	  			setRenderCount( renderCount );
				
  			}
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks on image elements
	//
	//	------------------------------------------------------------
  	
  	const onClickImgHandler = (event: React.MouseEvent<HTMLImageElement>) =>
  	{
  	
  		const button: HTMLImageElement = event.currentTarget;
  		
  		// button to launch a service.
  		if (button.id.length > 13)
  			if (button.id.slice( 0, 13 ) === "startService_")
  			{
  			
  				// get site and service indexes.
				const index: { siteIndex: number, serviceIndex: number } =
									getSiteAndServiceIndexes( { serviceID: button.id.slice( 13 - button.id.length ) } );
				if (index.siteIndex > -1 && index.serviceIndex > -1)
				{
  				
  					// get the service details.
  					var associatedServices: object[] = sServicesList[ index.siteIndex ][ 'associated_services' ];
					var service: object = associatedServices[ index.serviceIndex ];
					
					// get the type.
					var serviceType: string = "";
			  		if ('type' in service)
			  			if (typeof service[ 'type' ] === 'string')
			  				serviceType = service[ 'type' ];
			  					
			  		// get the host column.
			  		var host: string = "";
			  		if ('host' in service)
			  			if (typeof service[ 'host' ] === 'string')
			  				host = service[ 'host' ];
			  				
			  		// get the prefix column.
			  		var prefix: string = "";
			  		if ('prefix' in service)
			  			if (typeof service[ 'prefix' ] === 'string')
			  				prefix = service[ 'prefix' ];
			  				
			  		// get the port column.
			  		var port: number = -1;
			  		if ('port' in service)
			  			if (typeof service[ 'port' ] === 'number')
			  				port = service[ 'port' ];
			  				
			  		// get the path column.
			  		var path: string = "";
			  		if ('path' in service)
			  			if (typeof service[ 'path' ] === 'string')
			  				path = service[ 'path' ];
			  				
			  		// get the user token for this user/service.
			  		var token: string = "";
					var item: UserToken = findUserToken( { siteIndex: index.siteIndex, serviceIndex: index.serviceIndex } );
					if (item.savedValues.usingToken === true)
						token = '/user/' + item.savedValues.username + '?token=' + item.savedValues.token;

			  		// launch Jupyter notebook.
			  		if (serviceType.toUpperCase() === 'JUPYTERHUB')
			  		{
				  	
	  					// open the notebook url in a new tab.
	  					var url: string = prefix + '://' + host;
	  					if (port > -1)
	  						url = url + ':' + port;
	  					if (path !== '')
	  						url = url + path;
	  					if (token !== '')
	  						url = url + token;

	  					if (host !== 'localhost')
		  					window.open( url, '_blank', 'noreferrer')
		  				else
		  				{
		  					props.launchNotebook( { url: url } );
		  				}
	  				}
			  				
			  		// launch CANFAR and Azimuth
			  		if (serviceType.toUpperCase() === 'CANFAR' ||
			  			serviceType.toUpperCase() === 'AZIMUTH')
			  		{
				  	
	  					// open the notebook url in a new tab.
	  					var url: string = prefix + '://' + host;
	  					if (port > -1)
	  						url = url + ':' + port;
	  					if (path !== '')
	  						url = url + path;
	  					if (token !== '')
	  						url = url + token;
	  					console.log( url );
	  					window.open( url, '_blank', 'noreferrer')
	  					
	  				}
  				
  				}
  			
  			}
  		
  		// button to view more info on a service.
  		if (button.id.length > 12)
  			if (button.id.slice( 0, 12 ) === "infoService_")
  			{
  			
  				// get site and service indexes.
				var index: { siteIndex: number, serviceIndex: number } =
									getSiteAndServiceIndexes( { serviceID: button.id.slice( 12 - button.id.length ) } );
				if (index.siteIndex > -1 && index.serviceIndex > -1)
				{
  				
  					// disable to info if it's already displayed.
  					if (sServiceInfo.siteIndex === index.siteIndex && sServiceInfo.serviceIndex === index.serviceIndex)
  						index = {siteIndex: -1, serviceIndex: -1};
  						
  					// update the state.
  					setServiceInfo( {siteIndex: index.siteIndex, serviceIndex: index.serviceIndex} );
  					
  				}
  			
  			}
  	
  	} // onClickImgHandler

	//	------------------------------------------------------------
	//
	//	Part of the quicksort algorithm.
	//	Uses the rightmost element as the starting element 
	//	Rearranges the values within the array range so that all the values
	//	on the left are less than the starting element and those on the right
	//	are greater
	//	Treats an 'undefined' range as the largest possible value
	//	The items to the left and right are unsorted
	//	Returns the new index of the starting element
	//
	//	------------------------------------------------------------
	
	function partition( args: { array: any[], low: number, high: number } )
	{
	
		// choose the rightmost element as the starting element
		var pivot: number = -1;
		if ('distance' in args.array[ args.high ])
			pivot = args.array[ args.high ][ 'distance' ];
		else
			return args.high;

		// create a reference to before the first element
		// this represents the position we will move all smaller elements to
		var i: number = args.low - 1;

		// traverse through all the elements comparing each element with the starting element
		for ( var j = args.low; j < args.high; j++ )
			if ('distance' in args.array[ j ])
				if (args.array[ j ][ 'distance' ] <= pivot)
				{
					// if the element we are checking is smaller than the pivot
					// swap this element with the element to the right of index i
					i++;
					let temp = args.array[ i ];
					args.array[ i ] = args.array[ j ];
					args.array[ j ] = temp;
				}

		// finally move the starting element so that it is to the immediate right of the smaller elements
		let temp = args.array[ i + 1 ];
		args.array[ i + 1 ] = args.array[ args.high ];
		args.array[ args.high ] = temp;

		//Return the position of the starting element
		return (i + 1);
		
	} // partition

	//	------------------------------------------------------------
	//
	//	Uses the quicksort algorithm to sort the sites by distance
	//	Calls the partition function so that it knows all the elements to
	//	the left of a known position are smaller and all elements to the right
	//	are larger.
	//	It then recursively calls itself, once for the sub-array on the left and
	//	once for the sub-array on the right.
	//	This continues until the sub-arrays are length one
	//
	//	------------------------------------------------------------

	function sortByDistance( args: { array: any[], low: number, high: number } )
	{
	
		// first check to see if we are done or not
		if (args.low < args.high)
		{
		
			// find a pivot element such that all smaller elements are on the left and larger on the right
			let p = partition( { array: args.array, low: args.low, high: args.high } );

			// recursive call for the smaller elements
			sortByDistance( { array: args.array, low: args.low, high: p - 1 } );

			// recursive call for the larger elements
			sortByDistance( { array: args.array, low: p + 1, high: args.high } );
			
		}
		
	} // sortByDistance

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	return	(
	
		<div className = "search-compute">
		
			<div className = "search-compute-scrollbox-container">
			
				<div className = "search-compute-scrollbox">
				
					<div className = {sLoadingComponent === true && props.tableDisplayed == true ? "search-results-table-loading" : "search-results-table-loaded"}>
						<img	className = "animated-gears"
							src = {gearsIcon}
							alt = ""
							width = "60"
							height = "60" />
						Loading
					</div>
					
					{
						sServicesList.map( (item, index) => (	<Site	siteIndex = {index}
												row = {item}
												findUserToken = {findUserToken}
												serviceInfo = {sServiceInfo}
												renderCount = {sRenderCount}
												inputHandler = {inputHandler}
												onCheckBoxChange = {onCheckBoxChange}
												onClickHandler = {onClickHandler}
												onClickImgHandler = {onClickImgHandler} /> ) )
					}
					<div className = "flex-expanding"></div>
				
				</div>
			
			</div>
			
		</div>
		
		)

} // SearchComputeTable
