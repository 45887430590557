import React, { useState } from 'react';
import '../App.css';
import './search-compute.css';
import CSS from 'csstype';
import '../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// icons

// classes
import { AccessToken } from '../types';
import ToolButton from '../tools/tool-button';
import { CheckBox } from '../tools/controls';
import SearchComputeTable from './search-compute-table';
import SearchComputeSiteList from './search-compute-site-list';
import SearchComputeServiceTypesList from './search-compute-service-types-list';
import SearchResultsFooter from '../tools/search-results/search-results-footer';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function SearchCompute( props:	{
						tokenRefreshCount: number,
						taskExecutor: any,
						renewTokens: any,
						launchNotebook: any,
						tabVisible: boolean
						} )
{

	// translation function
	const { t } = useTranslation();

  	const tableCol: CSS.Properties =
  			{
  			padding: '8px 10px 0px 0px'
  			}
  	
  	// input boxes.
  	const [description, setDescription] = useState<string>( "" );
  	
  	// check boxes.
  	const [gpu, setGPU] = useState<boolean>( false );
  	const [largeScratch, setLargeScratch] = useState<boolean>( false );
  	const [highMemory, setHighMemory] = useState<boolean>( false );
  	const [fastScratch, setFastScratch] = useState<boolean>( false );

	// location
	//const [latitude, setLatitude] = useState<number|undefined>( ()=>CurrentLatitude() );
	//const [longitude, setLongitude] = useState<number|undefined>( ()=>CurrentLongitude() );
	const [latitude, setLatitude] = useState<number|undefined>( undefined );
	const [longitude, setLongitude] = useState<number|undefined>( undefined );  	

  	// select boxes.
  	const [siteValue, setSiteValue] = useState<string>( '' );
  	const [hardwareType, setHardwareType] = useState<string>( '' );
	const [serviceType, setServiceType] = useState<string>( '' );
  	const [middlewareVersion, setMiddlewareVersion] = useState<string>( '' );
  	
  	// current parameters for the results table.
  	const [tableSite, setTableSite] = useState<string>( '' );
  	const [tableRenderCount, setTableRenderCount] = useState<number>( 0 );
  	const [tableDisplayed, setTableDisplayed] = useState<boolean>( true );
  	const [tableGPU, setTableGPU] = useState<boolean>( false );
  	const [tableLargeScratch, setTableLargeScratch] = useState<boolean>( false );
  	const [tableHighMemory, setTableHighMemory] = useState<boolean>( false );
  	const [tableFastScratch, setTableFastScratch] = useState<boolean>( false );
  	const [tableDescription, setTableDescription] = useState<string>( "" );
  	const [tableHardwareType, setTableHardwareType] = useState<string>( "" );
	const [tableServiceType, setTableServiceType] = useState<string>( "" );
  	const [tableMiddlewareVersion, setTableMiddlewareVersion] = useState<string>( "" );
	const [tableLatitude, setTableLatitude] = useState<number|undefined>( undefined );
	const [tableLongitude, setTableLongitude] = useState<number|undefined>( undefined );  
	
  	// pages and rows.
  	const [page, setPage] = useState<number>( 0 );
  	const [pageSize, setPageSize] = useState<number>( 10 );
  	const [pages, setPages] = useState<number>( 0 );
  	const [rows, setRows] = useState<number>( 0 );
  	
  	// filter box maximised, or not?
  	const [filterMaximised, setFilterMaximised] = useState<boolean>( true );

	//	------------------------------------------------------------
	//
	//	Handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === "description")
  			setDescription( inputBox.value );
  		if (inputBox.name === "middlewareVersion")
  			setMiddlewareVersion( inputBox.value );
		if (inputBox.name === "latitude")
			try
			{
				const val: number = Number( inputBox.value );
				setLatitude( val );
			}
			catch
			{
			}
		if (inputBox.name === "longitude")
			try
			{
				const val: number = Number( inputBox.value );
				setLongitude( val );
			}
			catch
			{
			}
		
		// update the state for any check boxes that have been changed.
		if (inputBox.name === "gpu")
			setGPU( inputBox.checked );
		if (inputBox.name === "largeScratch")
			setLargeScratch( inputBox.checked );
		if (inputBox.name === "highMemory")
			setHighMemory( inputBox.checked );
		if (inputBox.name === "fastScratch")
			setFastScratch( inputBox.checked );
  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	Restrict input box entry to numbers only, plus . , + -
	//
	//	------------------------------------------------------------
  	
  	const numericKeysOnly = (event: React.KeyboardEvent<HTMLInputElement>) =>
  	{
  	
  		if ((event.charCode < 48 || event.charCode > 57) && (event.charCode < 43 || event.charCode > 46))
  			event.preventDefault();
  	
  	} // numericKeysOnly

	//	------------------------------------------------------------
	//
	//	Handler for select box and check box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const inputBox = event.target;
		
		// update the selected value of the location listbox.
		if (inputBox.name === "lst-hardware-type")
			setHardwareType( inputBox.value );
		if (inputBox.name === "lst-service-type")
			setServiceType( inputBox.value );
				
	} // onChangeHandler

	//	------------------------------------------------------------
	//
	//	Handler for changes to the radio buttons.
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.currentTarget;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === "hardwareType")
  			setHardwareType( inputBox.value );
		if (inputBox.name === "serviceType")
			setServiceType( inputBox.value );
  		if (inputBox.name === "middleware")
  			setMiddlewareVersion( inputBox.value );
  		if (inputBox.name === "gpu")
  			setGPU( inputBox.checked );
			
		// if we click a search button on the search form then open a new results tab.
		if (inputBox.name === "searchData")
		{
		
			// run the query.
			setTableSite( siteValue );
			setTableGPU( gpu );
			setTableLargeScratch( largeScratch );
			setTableHighMemory( highMemory );
			setTableFastScratch( fastScratch );
			setTableDescription( description );
			setTableHardwareType( hardwareType );
			setTableMiddlewareVersion( middlewareVersion );
			setTableServiceType( serviceType );
			setTableDisplayed( true );
			setTableRenderCount( tableRenderCount + 1 );
			setTableLatitude( latitude );
			setTableLongitude( longitude );
		
		}
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{

		const button: HTMLButtonElement = event.currentTarget;
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
		
	} // onClickButtonHandler

	//	-------------------------------------------------
	//
	//	function that handles a change in the desired page size
	//
	//	-------------------------------------------------
  	
	const pageSizeOnChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
  	{

		var newPageSize: number = pageSize;
		try
		{
			newPageSize = parseInt( event.target.value );
		}
		catch
		{
		}
		
		// calculate the row index of the first row.
		var firstRow: number = (pageSize * page);
		
		// calculate the new page number, and update the state.
		setPage( Math.floor( firstRow / newPageSize ) );
		setPageSize( newPageSize );
  	
  	} // pageSizeOnChangeHandler

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected site on the search form
	//
	//	-------------------------------------------------
  	
  	const siteOnChangeHandler = function( args: { site: string } )
  	{
  	
  		setSiteValue( args.site );
  	
  	} // siteOnChangeHandler

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected service type on the search form
	//
	//	-------------------------------------------------

	const serviceTypeOnChangeHandler = function( args: {serviceType: string } )
	{
		setServiceType( args.serviceType );

	} // serviceTypeOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Handles an event in a component that requires an update of state.
	//
	//	------------------------------------------------------------
	
	function updateState( props: 	{
					page: number,
					pages: number,
					rows: number
					} )
	{
	
		if (props.page !== undefined)
			setPage( props.page );
		if (props.pages !== undefined)
			setPages( props.pages );
		if (props.rows !== undefined)
			setRows( props.rows );
	
	} // updateState

	/*
	Sets the latitude and longitude to the users current geolocation
	*/
	
	const onClickCurrentLocation = (event: React.MouseEvent<HTMLElement>) =>
	{
	
		setCurrentLocation()
		
	} // onClickCurrentLocation

	/*
	Sets the latitude and longitude to the users current geolocation
	Only works if the users browser is set to allow location sharing
	*/
	
	function setCurrentLocation()
	{
	
		function success(position: any){
			setLatitude(position.coords.latitude);
			setLongitude(position.coords.longitude);
		}
		function error(error:any){
			console.log("Unable to retrieve location");
			switch(error.code)
			{
				case error.PERMISSION_DENIED:
				console.log("geolocation error: permission denied");
				break;
				case error.POSITION_UNAVAILABLE:
				console.log("geolocation error: position unavailable");
				break;
				case error.TIMEOUT:
				console.log("geolocation error: timeout");
				break;
				default:
				console.log("unknown geolocation error")
			}
			setLatitude(undefined);
			setLongitude(undefined);
		}

		if (navigator.geolocation){
			navigator.geolocation.getCurrentPosition(success, error, {timeout:10000});
		} else {
			console.log("No geolocation support");
		}
		
	} // setCurrentLocation

	/*
	Returns the users current latitude
	Only works if the users browser is set to allow location sharing
	*/
	
	function currentLatitude()
	{
	
		function success(pos: any)
		{
			//There is a delay when getCurrentPosition is called that causes the default 'undefined' to be returned
			//To get round this I call setLatitude and then (for full safety) also return the value
			setLatitude(pos.coords.latitude);
			return pos.coords.latitude;
		}
		function error(error:any)
		{
			return undefined;
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(success, error, {timeout:10000});
		} else {
			return undefined;
		}
		return undefined;
		
	} // currentLatitude

	/*
	Returns the users current longitude
	Only works if the users browser is set to allow location sharing
	*/
	 
	function currentLongitude()
	{
	
		function success(pos: any)
		{
			//There is a delay when getCurrentPosition is called that causes the default 'undefined' to be returned
			//To get round this I call setLongitude and then (for full safety) also return the value
			setLongitude(pos.coords.longitude);
			return pos.coords.longitude;
		}
		function error(error: any)
		{
			return undefined;
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(success, error, {timeout: 10000});
		} else {
			return undefined;
		}
		return undefined;
		
	} // currentLongitude

	return	(
	
		<div className = "search-compute-container">
		
			{/*
			//
			//	minimised filter box. click once to expand.
			//
			*/}
		    	<button	className = "minimised-filter"
		    			name = "maximiseFilter"
		    			type = "button"
		    			onClick = {onClickButtonHandler}
		    			data-maximised = {filterMaximised === true ? "T" : "F"}
		    			data-row = "T" >
		    	    	<div className = "flex-15px"></div>
		    		<div className = "maximise">&raquo;</div>
		    		<div className = "flex-row">
			    		<div className = "rotated-text-box">Filter</div>
			    	</div>
		    	</button>
		
			{/*
			//
			//	maximised filter box.
			//
			*/}
			<div	className = "search-form"
				data-maximised = {filterMaximised === true ? "T" : "F"}>
			
		    	    	<div className = "flex-15px"></div>
		    	    	<div className = "flex-row">
		    			<div className = "form-title">{t( "Filter" )}</div>
		    			<div className = "flex-expanding"></div>
		    			<button	className = "minimise"
		    					name = "minimiseFilter"
		    					type = "button"
		    					onClick = {onClickButtonHandler}>&laquo;</button>
		    			<div style = {{ flex: '0 0 18px' }}></div>
	    			</div>
		    	    	<div className = "flex-15px"></div>
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	site filter box
		    	    	//
		    	    	*/}
	    			<div className = "search-compute-filter-site">
					<SearchComputeSiteList	key = {props.tokenRefreshCount}
								changeSite = {siteOnChangeHandler}
								placeholderShown = {siteValue === ''} />
				</div>
		    	    	<div className = "flex-10px"></div>
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	description filter box
		    	    	//
		    	    	*/}
	    	    		<div className = "search-compute-filter-description">
    	    				<input	className = "compute-input-field"
    	    					type = "text"
    	    					name = "description"
    	    					placeholder = {t("Description")}
    	    					onChange = {inputHandler}
						maxLength = {50}></input>
	    	    		</div>
		    	    	<div className = "flex-10px"></div>
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	hardware capabilities checkboxes
		    	    	//
		    	    	*/}
	    			<div className = "form-text">{t("Hardware capabilities")}:</div>
		    	    	<div className = "flex-5px"></div>
    	    			<div className = "search-compute-filter-hardware">
    	    				<div className = "search-compute-filter-hardware-div">
    	    					<div className = "search-compute-filter-hardware-div-cell">
	    	    					<CheckBox	key = {t('GPU') + (props.tabVisible ? 't' : 'f')}
	    	    							name = 'gpu'
	    	    							value = 'GPU'
	    	    							text = {t('GPU')}
	    	    							checked = {gpu === true}
	    	    							onChange = {inputHandler} />
	    	    				</div>
	    	    				<div className = "search-compute-filter-hardware-div-cell">
	    	    					<CheckBox	key = {t('High memory') + (props.tabVisible ? 't' : 'f')}
	    	    							name = 'highMemory'
	    	    							value = 'highMemory'
	    	    							text = {t('High memory')}
	    	    							checked = {highMemory === true}
	    	    							onChange = {inputHandler} />
	    	    				</div>
    	    				</div>
    	    				<div className = "search-compute-filter-hardware-div">
    	    					<div className = "search-compute-filter-hardware-div-cell">
	    	    					<CheckBox	key = {t('Large scratch') + (props.tabVisible ? 't' : 'f')}
	    	    							name = 'largeScratch'
	    	    							value = 'largeScratch'
	    	    							text = {t('Large scratch')}
	    	    							checked = {largeScratch === true}
	    	    							onChange = {inputHandler} />
	    	    				</div>
    	    					<div className = "search-compute-filter-hardware-div-cell">
	    	    					<CheckBox	key = {t('Fast scratch') + (props.tabVisible ? 't' : 'f')}
	    	    							name = 'fastScratch'
	    	    							value = 'fastScratch'
	    	    							text = {t('Fast scratch')}
	    	    							checked = {fastScratch === true}
	    	    							onChange = {inputHandler} />
	    	    				</div>
    	    				</div>
    	    			</div>
		    	    	<div className = "flex-10px"></div>
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	hardware type filter dropdown
		    	    	//
		    	    	*/}
	    	    		<div className = "search-compute-filter-type">
				    	<select	name = "lst-hardware-type"
				    			className = "hardware-listbox"
				    			multiple = {false}
				    			value = ""
				    			onChange = {onChangeHandler}
				    			data-placeholder-shown = {hardwareType === "" ? "T" : "F"}>
				    		<option hidden value = "">{t("Select hardware type")}</option>
				    		<option label = {t("Any")} value = "any"> Any </option>
				    		<option label = {t("Container")} value = "container"> Container </option>
				    		<option label = {t("Bare metal")} value = "bare_metal"> Bare Metal </option>
				    		<option label = {t("Virtual machine")} value = "vm"> Virtual Machine </option>
					</select> 
	    	    		</div>
	    	    		<div className="flex-10px"/>
	    	    		
				{/* key = {props.siteCapabilitiesToken.access_token} */}
				<div className="search-compute-filter-service-type">
					<SearchComputeServiceTypesList	key = {props.tokenRefreshCount}
										changeServiceType = {serviceTypeOnChangeHandler}
										placeholderShown = {serviceType === ''} />
				</div>
				<div className="flex-10px"/>
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	middleware filter box
		    	    	//
		    	    	*/}
	    	    		{/*<div className = "search-compute-filter-middleware">
    	    				<input	className = "compute-input-field"
    	    					type = "text"
    	    					name = "middlewareVersion"
    	    					placeholder = {t("Middleware version")}
    	    					onChange = {inputHandler}></input>
	    	    		</div>
				<div className="flex-10px"/>*/}
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	location filter box
		    	    	//
		    	    	*/}
				<div className="form-title">{t("Sort")}</div>
				<div className="flex-15px"/>
				<div className="flex-column">
	    	    			<div className = "search-compute-filter-location">
						<input	name = "latitude"
							className = "compute-input-field"
							type = "text"
							placeholder = {t("Latitude")}
							step = "0.1"
							min = "-90.0"
							max = "90.0"
							onKeyPress = {numericKeysOnly}
							onChange = {inputHandler}
							maxLength = {20} />
						<div className="flex-5px"/>
						<div className="compute-input-uom">&deg;N</div>
					</div>
				</div>
				<div className="flex-5px"/>
				<div className="flex-column">
	    	    			<div className = "search-compute-filter-location">
						<input	name = "longitude"
							className = "compute-input-field"
							type = "text"
							placeholder = {t("Longitude")}
							step = "0.1"
							min = "-180.0"
							max = "180.0"
							onKeyPress = {numericKeysOnly}
							onChange = {inputHandler}
							maxLength = {20} />
						<div className="flex-5px"/>
						<div className="compute-input-uom">&deg;E</div>
					</div>
				</div>
				<div className="flex-20px"></div>
				
	    			{/*
	    			//
	    			//	search button
	    			//
	    			*/}
				<div className = "form-button-container">
		    	    		<ToolButton	key = {t("Search") + (props.tabVisible ? 't' : 'f')}
		    	    				name = "searchData"
		    	    				onClick = {onClickHandler}
		    	    				text = {t("Search")}
		    	    				primary = {true} />
		    	    	</div>
		    		
			</div>
			<div className = "transparent-vertical-separator"></div>
			
			{/*
			//
			//	the SearchComputeTable component runs a search query with the given parameters, and displays the results in a table.
			//
			*/}
			<SearchComputeTable	key = {tableRenderCount.toString() + props.tokenRefreshCount}
						tableDisplayed = {tableDisplayed}
						site = {tableSite}
						gpu = {tableGPU}
		    				largeScratch = {tableLargeScratch}
		    				highMemory = {tableHighMemory}
		    				fastScratch = {tableFastScratch}
		    				description = {tableDescription}
		    				hardwareType = {tableHardwareType}
		    				middlewareVersion = {tableMiddlewareVersion}
		    				serviceType = {tableServiceType}
		    				userLatitude = {tableLatitude}
		    				userLongitude = {tableLongitude}
		    				taskExecutor = {props.taskExecutor}
		    				renewTokens = {props.renewTokens}
		    				launchNotebook = {props.launchNotebook} />
		
		</div>
		
		)

} // SearchCompute
