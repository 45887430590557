import React, { useState } from 'react';
import '../App.css';
import './search-catalog.css';
// import CSS from 'csstype';
import '../tools/search-results/search-results.css';

// functions
import { APIPrefix } from '../functions';

// classes
import ToolSmall from '../tools/tool-small';
import { HideDropdownMenu } from '../functions';
import SearchResultsTable from './search-results-table';
//import SearchResultsInfo from './search-results-info';
import SearchResultsFooter from '../tools/search-results/search-results-footer';
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

export type SelectedRowsType =
{

	tabID: string;
	selectedRows:	{
			dataTypeIndex: number,
			selectedRows: number[]
			}[];

} // SelectedRowsType

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	Display a SearchResultsTable component for each search-results tab, only
//	one of which can be visible at any time.
//
//	--------------------------------------------------------------------------

function SearchResults( args:	{
				dptRetrieved: boolean,
				tab: number,
				page: number[],
				pageSize: number,
				rowCheckedHandler: any,
				dataProductType: { dpt: string, index: number }[],
				selectedRows: { dataTypeIndex: number, selectedRows: number[] }[],
				viewInAladin: any,
				ra: number,
				dec: number,
				fov: number,
				obs_publisher_did: string[],
				selectedID: { id: string, ra: number, dec: number }[],
				highlightedID: string,
				setHighlightedID: any,
				initiateDataManagementSearchEvent: any
				} )
{
	
	if (args.dptRetrieved === true)
		return	(
			<>
			{
				args.dataProductType.map
				(
					(dpt, index) =>
					(
						<div	className = {args.tab === index ? "search-results-table-holder-visible" : "search-results-table-holder-hidden"} >
		    					<SearchResultsTable	key = {"dpt" + index.toString() + "_" + args.page[ index ].toString() + "_" + args.pageSize.toString()}
										//dataset = {args.dataset}
										ra = {args.ra}
										dec = {args.dec}
										fov = {args.fov}
										obs_publisher_did = {args.obs_publisher_did}
										dataProductType = {dpt.dpt}
										page = {args.page[ index ]}
										pageSize = {args.pageSize}
										rowCheckedHandler = {args.rowCheckedHandler}
										selectedRows = {args.selectedRows.find( element => element.dataTypeIndex == index )}
										viewInAladin = {args.viewInAladin}
										selectedID = {args.selectedID}
										highlightedID = {args.highlightedID}
										setHighlightedID = {args.setHighlightedID}
										initiateDataManagementSearchEvent = {args.initiateDataManagementSearchEvent} />
						</div>
					)
				)
			}
			</>
			)
	else
		return	(
			<div className = "flex-expanding"></div>
			)

} // SearchResults

// ------------------------------------------------------------
//
//	Display the search results tabs at the top of the
//	screen.
//
// ------------------------------------------------------------

function TabBar( args:	{
			tabID: string,
			dataProductType: { dpt: string, index: number }[],
			selectedTab: number,
			totalCount: number[],
			onClickHandler: any,
			tabDropdownDisplayed: boolean,
			setTabDropdownDisplayed: any,
			pages: number,
			pageSizeOnChangeHandler: any
			} )
{

	// translation function
	const { t } = useTranslation();
 
	// maximum number of tabs that can be displayed along the tab bar.
	const [sMaxTabs, setMaxTabs] = useState<number>( -1 );
	const [sTabWidth, setTabWidth] = useState<number>( 262 );
	const [sDropdownWidth, setDropdownWidth] = useState<number>( 40 );
	const [sFirstTab, setFirstTab] = useState<number>( 0 );
	
	// handle changes in size to the tab bar.
	const resizeTabBar =	React.useRef<ResizeObserver>
				(
					new ResizeObserver
					(
						(entries:ResizeObserverEntry[]) =>
						{
						
							// recalculate the number of tabs that we can display along the top. any other tabs
							// must be placed in a drop-down menu.
							if (entries[0].contentRect.width > 0)
							{
		  						const maxTabs: number = Math.floor( (entries[0].contentRect.width - 350) / 262 );
		  						const tabWidth: number = Math.floor( (entries[0].contentRect.width - 350) / maxTabs );
		  						const dropdownWidth: number = entries[0].contentRect.width - 310 - (maxTabs * tabWidth);
								setMaxTabs( maxTabs );
								setTabWidth( tabWidth );
								setDropdownWidth( dropdownWidth );
							}
							
						}
					)
				);

	// create a reference to the HTML Div element that contains the tab bar, so that we can monitor for changes in size.
	const tabButtons =	React.useCallback
				(
					(container: HTMLDivElement) =>
					{
						
						// check that this reference has actually been assigned to an element, and switch on observing.
    						if (container !== null)
        						resizeTabBar.current.observe( container );
    							
						// When element is unmounted, ref callback is called with a null argument
						// => best time to cleanup the observer
    						else
        						if (resizeTabBar.current)
            							resizeTabBar.current.disconnect();
    							
					}, [resizeTabBar.current]
				);
							
	// check if we need to hide the drop-down menu.
	if (args.tabDropdownDisplayed === true && sMaxTabs >= args.dataProductType.length)
		args.setTabDropdownDisplayed( false );
		
	// change the first tab displayed in the list to ensure that the selected tab is displayed.
	{
		var firstTab: number = sFirstTab;
		if (args.selectedTab > -1)
		{
			if (args.selectedTab < firstTab)
				firstTab = args.selectedTab;
			if (args.selectedTab >= firstTab + sMaxTabs)
				firstTab = args.selectedTab - sMaxTabs + 1;
		}
		if (args.dataProductType.length - firstTab - 1 < sMaxTabs)
			firstTab = args.dataProductType.length - sMaxTabs;
		if (firstTab < 0)
			firstTab = 0;
		if (firstTab != sFirstTab)
			setFirstTab( firstTab );
	}

	return	(
	
    		/* we have a header row containing the tabs for the search form and (multiple) results */
    		<div ref = {tabButtons} className = "search-results-tab-bar">
			    	
			{/* and then we have the results-per-page dropdown */}
			<div className = "search-catalog-form-title">{t( "Filter" )}</div>
    			
    			{/* first we have the dropdown button for displayed extra tabs */}
		    	<ToolSmall	name = "btnTabDropdown"
		    			text = "&#9662;"
		    			onClick = {args.onClickHandler}
		    			left = {true}
		    			displayDropdown = {true}
		    			tabWidth = {sDropdownWidth}
		    			disabled = {sMaxTabs >= args.dataProductType.length || sMaxTabs === -1}
		    			dropdown =	{
				    			<TabDropdownMenu	sTabDropdownDisplayed = {args.tabDropdownDisplayed}
				    						setTabDropdownDisplayed = {args.setTabDropdownDisplayed}
				    						tabID = {args.tabID}
				    						items = {args.dataProductType}
				    						totalCount = {args.totalCount}
				    						firstItem = {sFirstTab}
				    						lastItem = {sFirstTab + sMaxTabs - 1}
				    						buttonHandler = {args.onClickHandler} />
		    					} />
		    				
		    	{/* and then we have the results tabs */}	
			{
				args.dataProductType.filter
				(
					(dpt) => dpt.index >= sFirstTab && dpt.index < sFirstTab + sMaxTabs
				).map
				(
					(dpt) =>
					(
						<ToolSmall	name = {"btnDataProduct_" + args.tabID + "_dpt" + dpt.index.toString()}
								text = {dpt.dpt !== '' ? dpt.dpt : '<none>'}
								withNumber = {true}
								count = {args.totalCount[ dpt.index ]}
								onClick = {args.onClickHandler}
								selected = {args.selectedTab === dpt.index}
								left = {false}
								right = {dpt.index === args.dataProductType.length - 1 || dpt.index === sFirstTab + sMaxTabs - 1}
								tabWidth = {sTabWidth} />
					)
				)
			}
			    	
			{/* and then we have the results-per-page dropdown */}
			<div	key = {args.pages.toString()}
				className = "search-results-per-page">{t("Results per page:")}</div>
				
			<select	className = "search-results-per-page-dropdown"
					name = "searchResultsPerPage"
					onChange = {args.pageSizeOnChangeHandler} >
				<option value = "10">10</option>
				<option value = "25">25</option>
				<option value = "50">50</option>
			</select>
    			
    		</div>
	
		)

} // TabBar

// ------------------------------------------------------------
//
//	This dropdown mimics a droplist listbox, but
//	allows an image to be displayed next to each list item.
//
// ------------------------------------------------------------

function TabDropdownMenu( args:	{
					sTabDropdownDisplayed: boolean,
					setTabDropdownDisplayed: any,
					tabID: string,
					items: { dpt: string, index: number }[],
					totalCount: number[],
					firstItem: number,
					lastItem: number,
					buttonHandler: any
					} )
{

	// function that monitors for mouse clicks. we need to add {ref} to the DIV element of the dropdown menu.
	const { ref } = HideDropdownMenu(	{
						sDropdownDisplayed: args.sTabDropdownDisplayed,
						setDropdownDisplayed: args.setTabDropdownDisplayed
						} );
												
	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item on the
	//	dropdown menu.
	//
	//	------------------------------------------------------------

	function MenuItem( args: { name: string, text: string, count: number, onClick: any } )
	{

		return	(
		
			<button name = {args.name} className = "dropdown-list-item" onClick = {args.onClick}>
				<div className = "dropdown-list-item-text" data-align="L">{args.text}</div>
				{/*<div className="button-text-container"><div className="text-container-inner">{args.name}</div></div>*/}
				<div	style =	{{
							flex: '0 0 auto',
							justifyContent: 'right',
							display: 'flex',
							alignItems: 'center',
							margin: '2px 8px 2px 8px',
							height: '24px',
							width: 'auto'
							}}
					className = "non-selected-button-number-container">{args.count}</div>
			</button>
		
			)

	} // MenuItem

	return	(

		<div	ref = {ref}
			style =	{{
					display: (args.sTabDropdownDisplayed === true ? 'block' : 'none'),
					position: 'absolute',
					top: '100%',
					left: '0%',
					border: 'none',
					zIndex: '9'
					}} >
				
			<div className = "language-dropdown-menu">
				{
				args.items.filter
				(
					(dpt) => dpt.index < args.firstItem || dpt.index > args.lastItem
				).map
				(
					(dpt) =>
					(
						MenuItem(	{
								name: 'btnDataProduct_' + args.tabID + '_dpt' + dpt.index.toString(),
								text: dpt.dpt !== '' ? dpt.dpt : '<none>',
								count: args.totalCount[ dpt.index ],
								onClick: args.buttonHandler
								} )
					)
				)
				}
			</div>
			
		</div>
			
		)

} // TabDropdownMenu

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function SearchCatalogResults( props:	{
							key: string,
							rowCheckedHandler: any,
							tabID: string,
							hidden: boolean,
							dataset: string,
							ra: number,
							dec: number,
							fov: number,
							obs_publisher_did: string[],
							dataProductType: string | undefined,
							selectedRows?: SelectedRowsType,
							viewInAladin: any,
							selectedID: { id: string, ra: number, dec: number }[],
							highlightedID: string,
							setHighlightedID: any,
							initiateDataManagementSearchEvent: any
							} )
{

	//translation function
	const { t, i18n } = useTranslation();

	//	-------------------------------------------------
	//
	//	state variables
	//
	//	-------------------------------------------------
	
	const [sPageSize, setPageSize] = useState<number>( 10 );
	const [sTotal, setTotal] = useState<number[]>( [0] );
	const [sPages, setPages] = useState<number[]>( [0] );
	const [sPage, setPage] = useState<number[]>( [0] );
	const [sTab, setTab] = useState<number>( 0 );
	
	// store the data products types. there will be one tab for each type.
	const [dataProductType, setDataProductType] = useState< { dpt: string, index: number }[]>( [ { dpt: 'other', index: 0 } ] );
	const [dptRetrieved, setDPTRetrieved] = useState<boolean>( false );
	
	// boolean to hold whether the tab drop-down menu is displayed.
	const [sTabDropdownDisplayed, setTabDropdownDisplayed] = useState<boolean>( false );

	//	------------------------------------------------------------
	//
	//	An asynchronous function that calls the API to get a list
	//	of data-product types for this cone search.
	//
	//	------------------------------------------------------------
  	
  	const getDataProductTypes = async() =>
  	{
  	
  		console.log( 'getDataProductTypes:' );
  		console.log( props.dataProductType );

		try
		{
			
			// build the list of IDs as a comma-separated list.
			var requiredObsPublisherDid: string = '';
			if (props.obs_publisher_did.length > 0)
			{
			
				for ( var i = 0; i < props.obs_publisher_did.length; i++ )
					requiredObsPublisherDid = requiredObsPublisherDid + "'" + props.obs_publisher_did[ i ] + "',";
					
				// remove the last comma.
				requiredObsPublisherDid = requiredObsPublisherDid.slice( 0, -1 );
					
			}
		
			var params: string =	'?ra=' + props.ra.toString() +
						'&dec=' + props.dec.toString() +
						'&radius=' + props.fov.toString()
			if (requiredObsPublisherDid !== '')
				params = params +
						'&obs_publisher_did=' + encodeURIComponent( requiredObsPublisherDid )
			if (props.dataProductType !== undefined && props.dataProductType !== '<all>')
				params = params + '&data_product_type=' + encodeURIComponent( props.dataProductType === '' ? '<empty>' : props.dataProductType );
					
			const apiResult = await fetch( APIPrefix() + '/v1/data/get_product_types' + params );

			// status is 200 if we found the source, 400 if it wasn't found, or 500 if the query wasn't well formed.
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get the list of data-product types, with the number of records in each one.
				var returnedValues: { dataproduct_type: string, num_records: number }[] = returnedJson.result;
				
				// did we find any records?
				if (returnedValues.length > 0)
				{

					// generate arrays with the same length as the data-product types array.
					var dpt: string[] = [];
					var page: number[] = new Array( returnedValues.length );
					var total: number[] = new Array( returnedValues.length );
					var pages: number[] = new Array( returnedValues.length );
					
					// loop through the returned values.
					for ( let i = 0; i < returnedValues.length; i++ )
					{
						dpt.push( returnedValues[ i ].dataproduct_type );
						page[ i ] = 0;
						total[ i ] = returnedValues[ i ].num_records;
						pages[ i ] = Math.ceil( total[ i ] / sPageSize );
					}

					// set the data-product types array to the returned list.
					var newDPT: { dpt: string, index: number }[] =	dpt.map
												(
													(name, index) =>
													{
														return { dpt: name, index: index };
													}
												)
					setDataProductType( newDPT );
						
					setPage( page );
					setPages( pages );
					setTotal( total );
					
					// set a flag to confirm that we've collected the data-product types.
					setDPTRetrieved( true );
				
				}
				else
				{
				
					// generate arrays with the same length as the data-product types array.
					var newDPT: { dpt: string, index: number }[] = [ { dpt: 'other', index: 0 } ];
					var page: number[] = [ 0 ];
					var total: number[] = [ 0 ];
					var pages: number[] = [ 1 ];

					// set the data-product types array to the returned list.
					setDataProductType( newDPT );
						
					setPage( page );
					setPages( pages );
					setTotal( total );
					
					// set a flag to confirm that we've collected the data-product types.
					setDPTRetrieved( true );
				
				}
			
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // getDataProductTypes

	//	-------------------------------------------------
	//
	//	button handler for onClick events.
	//
	//	-------------------------------------------------
	
	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{
	
		event.preventDefault();

		const button: HTMLButtonElement = event.currentTarget;
		
		console.log( "button.name:" );
		console.log( button.name );

		// move to first page.
		if (button.name === "searchResultsFirst" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = 0;
			setPage( currentPage );
		}

		// move to previous page.
		if (button.name === "searchResultsPrevious" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] - 1;
			setPage( currentPage );
		}

		// move to page before previous page.
		if (button.name === "searchResultsMinus2" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] - 2;
			setPage( currentPage );
		}

		// move to previous page.
		if (button.name === "searchResultsMinus1" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] - 1;
			setPage( currentPage );
		}

		// move to next page.
		if (button.name === "searchResultsPlus1" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] + 1;
			setPage( currentPage );
		}

		// move to page after next page.
		if (button.name === "searchResultsPlus2" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] + 2;
			setPage( currentPage );
		}

		// move to next page.
		if (button.name === "searchResultsNext" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = currentPage[ sTab ] + 1;
			setPage( currentPage );
		}

		// move to last page.
		if (button.name === "searchResultsLast" && sTab >= 0)
		{
			var currentPage: number[] = Object.assign( [], sPage );
			currentPage[ sTab ] = (sPages[ sTab ] > 0 ? sPages[ sTab ] - 1 : 0 );
			setPage( currentPage );
				
		}
		
		if (button.name === "btnTabDropdown")
			setTabDropdownDisplayed( !sTabDropdownDisplayed );
				
		// if one of the tabs on a search results form has been pressed, then store the button name.
		const namePrefix = "btnDataProduct_" + props.tabID + "_dpt";
		if (button.name.length > namePrefix.length)
			if (button.name.slice( 0, namePrefix.length ) === namePrefix)
			{
			
				// cut the rest of the text from the button name to get the data product type.
				let resultsTab: string = button.name.slice( namePrefix.length - button.name.length );
				
				// convert to an integer.
				var tabIndex: number = -1;
				try
				{
					tabIndex = parseInt( resultsTab );
				}
				catch
				{
				}
				
				// update the state.
				if (tabIndex >= 0)
					setTab( tabIndex );
				
				// ensure the tab dropdown is closed.
				setTabDropdownDisplayed( false );
			
			}
				
	} // onClickHandler

	//	-------------------------------------------------
	//
	//	function that handles a change in the desired page size
	//
	//	-------------------------------------------------
  	
	const pageSizeOnChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
  	{

		var newPageSize: number = sPageSize;
		try
		{
			newPageSize = parseInt( event.target.value );
		}
		catch
		{
		}
		
		// calculate the row indexes of the first rows.
		var firstRow: number[] = new Array( sPage.length );
		for ( let i = 0; i < sPage.length; i++ )
			firstRow[ i ] = (sPageSize * sPage[ i ]);
		
		// calculate the new page numbers, and update the state.
		var currentPage: number[] = Object.assign( [], sPage );
		var pages: number[] = Object.assign( [], sPages );
		for ( let i = 0; i < sPage.length; i++ )
		{
			currentPage[ i ] = Math.floor( firstRow[ i ] / newPageSize );
			pages[ i ] = Math.ceil( sTotal[ i ] / newPageSize );
		}
		setPage( currentPage );
		setPages( pages );
  	
  		// update state with the selected value.
  		setPageSize( newPageSize );
  	
  	} // pageSizeOnChangeHandler

	//	-------------------------------------------------
	//
	//	function that will update the state of the selected rows when a row is ticked or unticked.
	//
	//	-------------------------------------------------
  	
  	const rowCheckedHandler = function( args: { row: number, ticked: boolean } )
  	{
  	
  		props.rowCheckedHandler( { tabID: props.tabID, dataTypeIndex: sTab, row: args.row, ticked: args.ticked } );
  	
  	} // rowCheckedHandler

	//	------------------------------------------------------------
	//
	//	Handles an update page event from the results footer.
	//
	//	------------------------------------------------------------
  	
  	const updatePage = function( props:	{
  						page: number
	  					} )
  	{
  	
  		if (sTab >= 0)
  		{
  			var currentPage: number[] = Object.assign( [], sPage );
  			currentPage[ sTab ] = props.page;
  			setPage( currentPage );
  		}
  	
  	} // updatePage

	//	-------------------------------------------------
	//
	//	component code
	//
	//	-------------------------------------------------
	
	// get the currently selected rows.
	var selectedRows: { dataTypeIndex: number, selectedRows: number[] }[] = [ { dataTypeIndex: -1, selectedRows: [] } ];
	if (props.selectedRows !== undefined)
		selectedRows = props.selectedRows.selectedRows;
		
	// retrieve the data-product types from the database.
	if (dptRetrieved === false)
		getDataProductTypes();
				
	return	(

		<div	key = {props.tabID}
			className = "search-results-tab"
			data-hidden = {props.hidden === true ? "T" : "F"} >
		
			{/* we have a small section where we display details on the database that has been queried */}
			{/*<SearchResultsInfo dataset={props.dataset} />*/}
	    	
	    		{/* we have a header row containing the tabs for the search form and (multiple) results */}
	    		<TabBar	tabID = {props.tabID}
	    				dataProductType = {dataProductType}
	    				selectedTab = {sTab}
	    				totalCount = {sTotal}
	    				onClickHandler = {onClickHandler}
	    				tabDropdownDisplayed = {sTabDropdownDisplayed}
	    				setTabDropdownDisplayed = {setTabDropdownDisplayed}
	    				pages = {sPageSize}
	    				pageSizeOnChangeHandler = {pageSizeOnChangeHandler} />
	    		
	    		{/* the results component contains results for each data product type. all but one of these result sets will
	    			be hidden */}
	    		<SearchResults	dptRetrieved = {dptRetrieved === true && sTotal[sTab] > 0}
	    				tab = {sTab}
	    				page = {sPage}
	    				pageSize = {sPageSize}
	    				rowCheckedHandler = {rowCheckedHandler}
	    				dataProductType = {dataProductType}
	    				selectedRows = {selectedRows}
	    				viewInAladin = {props.viewInAladin}
	    				ra = {props.ra}
	    				dec = {props.dec}
	    				fov = {props.fov}
	    				obs_publisher_did = {props.obs_publisher_did}
	    				selectedID = {props.selectedID}
	    				highlightedID = {props.highlightedID}
	    				setHighlightedID = {props.setHighlightedID}
	    				initiateDataManagementSearchEvent = {props.initiateDataManagementSearchEvent} />
				
			{/* the search-results footer contains the navigation buttons for moving between results pages */}
			<SearchResultsFooter	key = {sPages[sTab].toString() + sTotal[sTab].toString() + sPageSize.toString()}
						page = {sPage[ sTab ]}
						pageSize = {sPageSize}
						pages = {sPages[ sTab ]}
						rows = {sTotal[ sTab ]}
						updatePage = {updatePage} />
			
		</div>
	
		)

} // SearchCatalogResults
