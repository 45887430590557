import React, { useState, useRef, Ref, useEffect } from 'react';
import '../App.css';
import './data-management.css';
import CSS from 'csstype';
import '../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../icons/gears.gif';
import fileIcon from '../icons/file.128.png';

// types
import { AccessToken } from '../types';

// types relating to tasks.
import { TaskType } from '../tasks';
import { MoveDataToStorage } from '../tasks';
import { CurrentTask } from '../tasks';

// functions
import { APIPrefix } from '../functions';

// classes
import { ContextMenuType } from '../context-menu';
import { ContextMenu } from '../context-menu';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function DataManagementTable( props:	{
							key: string,
							tableDisplayed: boolean,
							namespace: string,
							filename: string,
							paramsLifetime: number,
							renewTokens: any,
							taskExecutor: any,
							storageAreas:	{
									site: string,
									storage_areas:	{
											associated_storage_id: string,
											storage_id: string,
											storage_type: string,
											relative_path: string,
											identifier: string
											}[]
									}[],
							setStorageAreas: any,
							setSelectedItems: any
							} )
{

	//	------------------------------------------------------------
	//
	//	constants
	//
	//	------------------------------------------------------------

	// translation function
	const { t } = useTranslation();
  			
  	// state variables.
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( true );
	const [sDataList, setDataList] = useState< { scope: string, name: string, did_type: string, bytes: number, length: number }[] >( [] );
	const [sSelectedItems, setSelectedItems] = useState< number[] >( [] );
	
	// context menu constants.
	const NUM_CONTEXT_MENUS: number = 3;
	const CONTEXT_MENU_WIDTH: number = 200;
	
	// list of context menus.
	enum CONTEXT_MENU
	{
	
		NONE = -1,
		MAIN,
		SITES,
		STORAGES
		
	} // ContextMenu
	
	// context menus state variables.
	const [sContextVisible, setContextVisible] = useState< boolean[] >( Array( NUM_CONTEXT_MENUS ).fill( false ) );
	const [sContextPos, setContextPos] = useState< { x: number, y: number }[] >( Array( NUM_CONTEXT_MENUS ).fill( { x: 0.0, y: 0.0 } ) );
	const [sContextRow, setContextRow] = useState< number[] >( Array( NUM_CONTEXT_MENUS ).fill( -1 ) );
	
	// size of data window.
	const [sDataWindowWidth, setDataWindowWidth] = useState< number >( 0.0 );
	const [sDataWindowHeight, setDataWindowHeight] = useState< number >( 0.0 );
	
	// store the context menu definition.
	const [sContextMenuDefinition, setContextMenuDefinition] = useState<	ContextMenuType[] >
				(	[
					
						// main context menu.
						{
							items:	[
									{
									text: t( 'Move to' ),
									childMenu: CONTEXT_MENU.SITES,
									onMouseEnter: true,
									onClick: false,
									enabled: false
									}
								],
							parentMenu: CONTEXT_MENU.NONE
						},
						
						// list of sites.
						{
							items:	[],
							parentMenu: CONTEXT_MENU.MAIN
						},
						
						// list of storage areas.
						{
							items: [],
							parentMenu: CONTEXT_MENU.SITES
						}
						
					] );

	//	------------------------------------------------------------
	//
	//	code for storing the size when the data window is resized.
	//
	//	------------------------------------------------------------
	
	// handle changes in size to the data window container.
	const resizeDataWindow =	React.useRef<ResizeObserver>
					(
						new ResizeObserver
						(
							(entries:ResizeObserverEntry[]) =>
							{
							
								// recalculate the number of tabs that we can display along the top. any other tabs
								// must be placed in a drop-down menu.
								if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0)
								{
			  						setDataWindowWidth( entries[0].contentRect.width );
			  						setDataWindowHeight( entries[0].contentRect.height );
			  						//console.log( "contentRect: height:" );
			  						//console.log( entries[0].contentRect.height );
								}
								
							}
						)
					);

	// create a reference to the HTML Div element that contains the data items, so that we can monitor for changes in size.
	const dataWindow =	React.useCallback
				(
					(container: HTMLDivElement) =>
					{
						
						// check that this reference has actually been assigned to an element, and switch on observing.
    						if (container !== null)
        						resizeDataWindow.current.observe( container );
    							
						// When element is unmounted, ref callback is called with a null argument
						// => best time to cleanup the observer
    						else
        						if (resizeDataWindow.current)
            							resizeDataWindow.current.disconnect();
    							
					}, [resizeDataWindow.current]
				);

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the page has loaded.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  	
		  		// load the data from the data-management end point of the data-management API.
		  		loadData();
		  		
				setLoadingComponent( false );
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads data from the data
	//	management API for a specific namespace.
	//
	//	------------------------------------------------------------
  	
  	async function loadData()
  	{
	
		try
		{

			var urlCommand: string = APIPrefix() + '/v1/data_management/list_data?';
			
			// token.
			urlCommand = urlCommand +	'namespace=' + props.namespace;
			if (props.filename !== '')
				urlCommand = urlCommand + '&filename=' + props.filename;

			try
			{
				
				const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
				if (apiResult.status === 200)
				{
				
					const returnedJson = await apiResult.json();

					// get data list.
					var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
					if (returnedJson.data_list !== undefined)
						dataList = returnedJson.data_list;
					
					// update the state with the list of returned data items.
					setDataList( dataList );
					
				}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
				if (apiResult.status === 401)
					props.renewTokens( {} );
				
			}
			catch (e)
			{
				console.log( e );
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // loadData

	//	------------------------------------------------------------
	//
	//	retrieves the scroll position of the container that
	//	holds the context menu.
	//
	//	------------------------------------------------------------
  	
  	function getContainerScrollPosition()
  	{
  	
  		var containerScrollPosition: number = 0.0;
	  		
		// get the DIV element with the vertical scroll bars, and find the scroll position.
		const id = 'scrollbox';
		const scrollDiv: HTMLDivElement | null = document.querySelector(`#${id}`);
		if (scrollDiv !== null)
			containerScrollPosition = scrollDiv.scrollTop;
  	
  		// return something.
  		return containerScrollPosition
  	
  	} // getContainerScrollPosition

	//	------------------------------------------------------------
	//
	//	builds a list of the selected items.
	//
	//	------------------------------------------------------------
  	
  	function getSelectedItems( args:	{
  						divID: number[]
  						} )
  	{
  	
  		var selectedItems:	{
  					namespace: string,
  					name: string,
  					size: number
  					}[] = [];
  					
  		// loop over the selected IDs.
		for ( var selectedItemIdx = 0; selectedItemIdx < args.divID.length; selectedItemIdx++ )
			selectedItems.push(	{
						namespace: props.namespace,
						name: sDataList[ args.divID[ selectedItemIdx ] ].name,
						size: sDataList[ args.divID[ selectedItemIdx ] ].bytes
						} );
  					
  		// return something.
  		return selectedItems;
  	
  	} // getSelectedItems

	//	------------------------------------------------------------
	//
	//	handle the click event for a context menu item by launching
	//	the move-data process.
	//
	//	------------------------------------------------------------
  	
  	function onContextMenuClick( args:	{
						menuID: number,
						rowID: number[]
						} )
  	{

  		// if we've clicked on a storage area on the storages menu
  		// then launch the move-data process.
  		if (args.menuID === CONTEXT_MENU.STORAGES)
  		{

			// build a list of selected files.
			const filesToMove:	{
						namespace: string,
						name: string
						}[] = [];
			for ( var selectedItemIdx = 0; selectedItemIdx < sSelectedItems.length; selectedItemIdx++ )
				filesToMove.push(	{
							namespace: props.namespace,
							name: sDataList[ sSelectedItems[ selectedItemIdx ] ].name
							} );
							
			// build parameters for moving some data to a storage location.
			const moveDataToStorage: MoveDataToStorage =	{
									toStorageAreaUUID: props.storageAreas[ args.rowID[ CONTEXT_MENU.SITES ] ].storage_areas[ args.rowID[ CONTEXT_MENU.STORAGES ] ].storage_id,
									lifetime: props.paramsLifetime,
									filesToMove: filesToMove
									}
			const currentTask: CurrentTask =	{
								taskType: TaskType.MOVE_DATA_TO_STORAGE,
								moveDataToStorage: moveDataToStorage
								}
			
			// call the API to move this data to the required storage area.
			props.taskExecutor( { currentTask: currentTask } );
					
		}
  	
  	} // onContextMenuClick

	//	------------------------------------------------------------
	//
	//	handle the mouse enter event for a context menu item
	//	by rebuilding the child menu.
	//
	//	------------------------------------------------------------
  	
  	function onContextMenuMouseEnter( args:	{
  							menuID: number,
  							rowID: number
  							} )
  	{

  		// if we've entered the 'Move to' option on the main context menu then
  		// rebuild the sites list menu.
  		if (args.menuID === CONTEXT_MENU.MAIN && args.rowID === 0)
  		{
  		
			var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
			
			// build the new sites context menu.
			contextMenuTmp[ CONTEXT_MENU.SITES ].items =	props.storageAreas.map
									(
										item =>
										(
											{
											text: item.site,
											childMenu: CONTEXT_MENU.STORAGES,
											onMouseEnter: true,
											onClick: false,
											enabled: true
											}
										)
									);
			
			// update the state.
			setContextMenuDefinition( contextMenuTmp );
  			
  		}
  		
  		// if we've entered a menu item on the sites context menu then
  		// rebuild the storage areas menu.
  		if (args.menuID === CONTEXT_MENU.SITES)
  		{
  		
			var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
			
			// build the new sites context menu.
			contextMenuTmp[ CONTEXT_MENU.STORAGES ].items =	props.storageAreas[ args.rowID ].storage_areas.map
										(
											item =>
											(
												{
												text: (item.identifier !== '' ? item.identifier : '<' + item.relative_path + '>'),
												childMenu: CONTEXT_MENU.NONE,
												onMouseEnter: false,
												onClick: true,
												enabled: true
												}
											)
										);
			
			// update the state.
			setContextMenuDefinition( contextMenuTmp );
  		
  		}
  	
  	} // onContextMenuMouseEnter

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Display an icon and name for each data item.
	//
	//	------------------------------------------------------------
	
	function DataItems()
	{
	
		function Icon( args: { item: any } )
		{
		
			if ('did_type' in args.item)
			{
				const did_type: string = args.item[ 'did_type' ].toUpperCase();
				switch (did_type)
				{
					case "DIDTYPE.DATASET":
						return	(
							<img src = {fileIcon} alt = "" width = "40" height = "40" />
							)
						break;
					case "DIDTYPE.FILE":
						return	(
							<img src = {fileIcon} alt = "" width = "40" height = "40" />
							)
						break;
					case "DIDTYPE.CONTAINER":
						return	(
							<img src = {fileIcon} alt = "" width = "40" height = "40" />
							)
						break;
					default:
						return	(
							<div className = "data-management-item-icon-empty">{did_type}</div>
							)
						break;
				}
			}
			else
				return	(
					<div className = "data-management-item-icon-empty"></div>
					)
					
		} // Icon

		//	------------------------------------------------------------
		//
		//	Handler for clicks on data items.
		//
		//	------------------------------------------------------------
	  	
	  	const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) =>
	  	{
	  	
	  		const divElement: HTMLDivElement = event.currentTarget;

	  		// check for a right-click.
	  		const rightClick: boolean = (event.type === 'contextmenu');

			// if the user clicked on the container, but not in any items, then we need to clear the selection.
			if (event.target instanceof HTMLDivElement)
			{
				const targetElement: HTMLDivElement = event.target;
				if (targetElement.id === 'container')
				{
				
					// clear selected items.
					setSelectedItems( [] );
					props.setSelectedItems( [] );
					
					// disable the context menu option for moving data.
					var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
					contextMenuTmp[ CONTEXT_MENU.MAIN ].items[ 0 ].enabled = false;
					setContextMenuDefinition( contextMenuTmp );
					
					// deselect all the context-menu rows, and set all menus to hidden.
					setContextRow( Array( NUM_CONTEXT_MENUS ).fill( -1 ) );
					setContextVisible( Array( NUM_CONTEXT_MENUS ).fill( false ) );
					
				}
			}

	  		// if we've right clicked somewhere in the container store the X and Y coordinates, and set the context menu visible.
	  		if (rightClick === true && divElement.id === 'container')
	  		{
	  		
	  			// get the DIV element with the vertical scroll bars, and find the scroll position.
				const id = 'scrollbox';
				const scrollDiv: HTMLDivElement | null = document.querySelector(`#${id}`);
				var scrollPos: number = 0.0;
				if (scrollDiv !== null)
					scrollPos = scrollDiv.scrollTop;

				var bounds = divElement.getBoundingClientRect();
				var contextPos: { x: number, y: number } = { x: event.clientX - bounds.left, y: event.clientY - bounds.top };
				
				// check if the context menu will appear off the right-hand side of the panel.
				if (bounds.width - contextPos.x < CONTEXT_MENU_WIDTH)
					contextPos.x = bounds.width - CONTEXT_MENU_WIDTH;
				if (contextPos.x < 0.0)
					contextPos.x = 0.0;
					
				// check if the context menu will appear off the bottom of the panel.
				if (bounds.height - contextPos.y + scrollPos < 50.0)
					contextPos.y = bounds.height + scrollPos - 50.0;
				if (contextPos.y < 0.0)
					contextPos.y = 0.0;
				
				// update state.
				var pos: { x: number, y: number }[] = sContextPos.slice();
				pos[ CONTEXT_MENU.MAIN ] = contextPos;
				setContextPos( pos );
				var contextVisible: boolean[] = sContextVisible.slice();
				contextVisible[ CONTEXT_MENU.MAIN ] = true;
				contextVisible[ CONTEXT_MENU.SITES ] = false;
				contextVisible[ CONTEXT_MENU.STORAGES ] = false;
				setContextVisible( contextVisible );
				
	  			event.preventDefault();
	  			
	  		}
				
			// check which data item was clicked on.
			if (divElement.id.length > 8)
				if (divElement.id.slice( 0, 8 ) === "itmData_")
				{
			
					// get the ID and convert to numeric.
					const divIDstr: string = divElement.id.slice( 8 - divElement.id.length );
					var divID: number = -1;
					try
					{
						divID = Number( divIDstr );
					}
					catch
					{
					}
					
					// check if this element is already selected.
					const alreadySelected: boolean = (sSelectedItems.indexOf( divID ) > -1);
					
					// set the Move To item in the context menu to enabled.
					var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
					contextMenuTmp[ CONTEXT_MENU.MAIN ].items[ 0 ].enabled = true;
					setContextMenuDefinition( contextMenuTmp );
					
					//
					// clear the selected items, and set the selected item list to this item. also close the context menu.
					//
					// we can do this if:
					//
					//	a. Ctrl and shift are not pressed, OR we have no selected items, and
					//	b. the item is not already selected
					//
					
					if (divID > -1 && ((event.ctrlKey === false && event.shiftKey === false) || sSelectedItems.length === 0) &&
						alreadySelected === false)
					{
						var selectedItems: number[] = [];
						selectedItems.push( divID );
						setSelectedItems( selectedItems );
						props.setSelectedItems( getSelectedItems( { divID: selectedItems } ) );
						setContextVisible( Array( NUM_CONTEXT_MENUS ).fill( false ) );
						setContextRow( Array( NUM_CONTEXT_MENUS ).fill( -1 ) );
					}
					
					//
					// add the selected item to the list.
					//
					// we can do this if:
					//
					//	a. Ctrl is pressed, and shift is not pressed
					//
					
					if (divID > -1 && event.ctrlKey === true && event.shiftKey === false)
					{
						var selectedItems: number[] = sSelectedItems.slice();
						if (selectedItems.indexOf( divID ) === -1)
							selectedItems.push( divID );
						setSelectedItems( selectedItems );
						props.setSelectedItems( getSelectedItems( { divID: selectedItems } ) );
					}
					
					//
					// add multiple items to the list.
					//
					// we can do this if:
					//
					//	a. Shift is pressed, and we have at least one selected item already.
					//
					
					if (divID > -1 && event.shiftKey === true && sSelectedItems.length > 0)
					{
					
						var selectedItems: number[] = sSelectedItems.slice();
						
						// get the last selected item.
						const lastItem: number = selectedItems[ selectedItems.length - 1 ];
						
						// loop over all the items in between.
						if (divID >= lastItem)
							for ( var i = lastItem; i <= divID; i++ )
							{
							
								// remove this item if it already exists.
								const index = selectedItems.indexOf( i );
								if (index > -1)
									selectedItems.splice( index, 1 );
									
								// add the item to the end of the list.
								selectedItems.push( i );
							
							}
						else
							for ( var i = lastItem; i >= divID; i-- )
							{
							
								// remove this item if it already exists.
								const index = selectedItems.indexOf( i );
								if (index > -1)
									selectedItems.splice( index, 1 );
									
								// add the item to the end of the list.
								selectedItems.push( i );
							
							}
						
						// update the state.
						setSelectedItems( selectedItems );
						props.setSelectedItems( getSelectedItems( { divID: selectedItems } ) );
						
					}
				
				}
	  	
	  	} // onClickHandler
	
		return	(
		
			<div	ref = {dataWindow} style = {{ height: '100%' }}
				className = "data-management-items"
				id = 'container'
				onClick = {onClickHandler}
				onContextMenu = {onClickHandler} >
				
				{/* add the list of files, datasets, etc */}
				{
					sDataList.map( (item, index) =>
					(
						<div	className = "data-management-item"
							id = {'itmData_' + index.toString()}
							data-selected = {sSelectedItems.indexOf( index ) > -1 ? "T" : "F"}
							onClick = {onClickHandler}
							onContextMenu = {onClickHandler} >
						
							<div className = "data-management-item-main">
								<div className = "flex-10px"></div>
								<Icon item = {item} />
								<div className = "data-management-item-details">
									<div style = {{ flex: '1 1' }} />
									<div className = "data-management-item-name" style = {{ overflowWrap: 'anywhere' }}>
										{'name' in item ? item[ 'name' ] : ''}
									</div>
									<div style = {{ flex: '1 1' }} />
								</div>
							</div>
							
						</div>
					) )
				}
				
				{/* add the context menus */}
				{
					sContextMenuDefinition.map
					(
						(item, index) =>
						(
							<ContextMenu	key = {index}
									contextMenuDefinition = {sContextMenuDefinition}
									menuID = {index}
									onClick = {onContextMenuClick}
									onMouseEnter = {onContextMenuMouseEnter}
									dataWindowWidth = {sDataWindowWidth}
									dataWindowHeight = {sDataWindowHeight}
									containerScrollPosition = {getContainerScrollPosition()}
									contextPos = {sContextPos}
									setContextPos = {setContextPos}
									contextRow = {sContextRow}
									setContextRow = {setContextRow}
									contextVisible = {sContextVisible}
									setContextVisible = {setContextVisible}
									numContextMenus = {NUM_CONTEXT_MENUS}
									contextMenuWidth = {CONTEXT_MENU_WIDTH} />
						)
					)
				}
				
			</div>
			
			)
	
	} // DataItems

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	return	(
	
		<div className = "data-management-table">
		
			<div className = "data-management-scrollbox-container">
			
				<div className = "data-management-scrollbox" id = "scrollbox">
				
					<div className = {sLoadingComponent === true && props.tableDisplayed == true ? "search-results-table-loading" : "search-results-table-loaded"}>
						<img	className = "animated-gears"
							src = {gearsIcon}
							alt = ""
							width = "60"
							height = "60" />
						Loading
					</div>
					
					{/* display the list of data items */}
					<DataItems	key = {sSelectedItems.length} />
					<div className = "flex-expanding"></div>
				
				</div>
			
			</div>
			
		</div>
		
		)

} // DataManagementTable
