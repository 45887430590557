import React from 'react';
import CSS from 'csstype';
import '../App.css';
import './search-catalog.css';
import '../tools/search-results/search-results.css';
import { withTranslation, WithTranslation } from "react-i18next";

// images
import infoIcon from '../icons/info.512.png';
import scissorsIcon from '../icons/scissors.512.png';
import gearsIcon from '../icons/gears.gif';
import downloadIcon from '../icons/download.512.png';
import viewAladinIcon from '../icons/view-aladin.512.png';

// functions
import { APIPrefix } from '../functions';

// classes
import ToolButton from '../tools/tool-button';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

type SourceRowType =
{
	name: string;
	ra: number;
	dec: number;
	collection: string;
	instrument: string;
}

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface SearchResultsTableProps extends WithTranslation
{

	key: string;
	ra: number;
	dec: number;
	fov: number;
	obs_publisher_did: string[];
	//dataset: string;
	dataProductType: string;
	page: number;
	pageSize: number;
	rowCheckedHandler: any;
	selectedRows?: { dataTypeIndex: number, selectedRows: number[] };
	viewInAladin: any;
	selectedID: { id: string, ra: number, dec: number }[];
	highlightedID: string;
	setHighlightedID: any;
	initiateDataManagementSearchEvent: any;

} // SearchResultsTableProps

interface SearchResultsTableState
{
	sourceList: SourceRowType[];
	columns: string[];
	loadingComponent: boolean;

} // SearchResultsTableState

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

class SearchResultsTable extends React.Component<SearchResultsTableProps, SearchResultsTableState>
{

	// class-level constants.
  	static readonly TABLE_COL: CSS.Properties =
  			{
  			padding: '3px 5px 3px 5px',
  			whiteSpace: 'nowrap'
  			}
  	static readonly TABLE_COL_SELECTED: CSS.Properties =
  			{
  			padding: '3px 5px 3px 5px',
  			whiteSpace: 'nowrap',
  			background: 'rgba( 255, 0, 0, 0.3 )'
  			}
  	static readonly TABLE_COL_HIGHLIGHTED: CSS.Properties =
  			{
  			padding: '3px 5px 3px 5px',
  			whiteSpace: 'nowrap',
  			background: 'rgba( 0, 255, 0, 0.2 )'
  			}

	constructor( props:SearchResultsTableProps )
	{
		super( props );
		this.state =
		{
			sourceList: [],
			columns: [],
			loadingComponent: true
		};
	};

	//	------------------------------------------------------------
	//
	//	Load the dataset when the component is rendered
	//
	//	------------------------------------------------------------

	async componentDidMount()
	{
	
		var urlCommand: string = APIPrefix() + '/v1/data/get_data';
			
		// build the list of IDs as a comma-separated list.
		var requiredObsPublisherDid: string = '';
		if (this.props.obs_publisher_did.length > 0)
		{
		
			for ( var i = 0; i < this.props.obs_publisher_did.length; i++ )
				requiredObsPublisherDid = requiredObsPublisherDid + "'" + this.props.obs_publisher_did[ i ] + "',";
				
			// remove the last comma.
			requiredObsPublisherDid = requiredObsPublisherDid.slice( 0, -1 );
				
		}
		
		// add ra, dec, and fov.
		urlCommand = urlCommand +	'?ra=' + this.props.ra +
						'&dec=' + this.props.dec +
						'&radius=' + this.props.fov
		if (requiredObsPublisherDid !== '')
			urlCommand = urlCommand +
						'&obs_publisher_did=' + encodeURIComponent( requiredObsPublisherDid )
		urlCommand = urlCommand +
						'&dataproduct_type=' + encodeURIComponent( this.props.dataProductType );
						
		// add page and page size.
		urlCommand = urlCommand +	'&page=' + this.props.page +
						'&page_size=' + (this.props.pageSize);
	
		try
		{
		
			const apiResult = await fetch( urlCommand );
			const returnedJson = await apiResult.json();

			// get source list.
			var sourceList: any = [];
			if (returnedJson.result !== undefined)
				sourceList = returnedJson.result;

			// extract a list of columns.
			var columns: string[] = [];
			
			// loop over all the rows.
			if (Array.isArray( sourceList ))
				sourceList.forEach
				(
					(element) =>
					{
					
						// get a list of keys for this object.
						var newColumns: string[] = Object.keys( element );
						
						// add these items if they don't already exist.
						newColumns.forEach
						(
							(column) =>
							{
								if (columns.findIndex( element => element === column ) == -1)
									columns.push( column );
							}
						)
						
					}
				);
			else
			
				// get a list of keys for this object.
				columns = Object.keys( sourceList );

			this.setState( {sourceList: sourceList, columns: columns, loadingComponent: false} );
			
      		}
      		catch (e)
      		{
      		console.log( e );
		}
		
	} // componentDidMount

	//	------------------------------------------------------------
	//
	//	Copies a string value into the clipboard
	//
	//	------------------------------------------------------------
	
	copyToClipboard = (str: string) =>
	{
	
		const el = document.createElement( "textarea" );
		el.value = str;
		el.setAttribute( "readonly", "" );
		el.style.position = "absolute";
		el.style.left = "-9999px";
		document.body.appendChild( el );
		const getSelection = document.getSelection();
		if (getSelection !== null)
		{
			const selected = getSelection.rangeCount > 0
						? getSelection.getRangeAt(0)
						: false;
			el.select();
			document.execCommand( "copy" );
			document.body.removeChild( el );
			if (selected)
			{
				getSelection.removeAllRanges();
				getSelection.addRange(selected);
			}
		}
  
	} // copyToClipboard

	//	------------------------------------------------------------
	//
	//	Get some information and download a file.
	//
	//	------------------------------------------------------------
	
	downloadFile( args: { row: number } )
	{
	
		// get the row that is to be retrieved.
		var row: any = this.state.sourceList[ args.row ];
		
		// get the access_url.
		var accessUrl: string = "";
		var accessUrlColID: number = this.state.columns.findIndex( element => element === 'access_url' );
		if (accessUrlColID > -1)
			accessUrl = row[ this.state.columns[ accessUrlColID ] ];
			
		// get the RA and Dec.
		var ra: string = "";
		var raColID: number = this.state.columns.findIndex( element => element === 's_ra' );
		if (raColID > -1)
			ra = row[ this.state.columns[ raColID ] ];
		var dec: string = "";
		var decColID: number = this.state.columns.findIndex( element => element === 's_dec' );
		if (decColID > -1)
			dec = row[ this.state.columns[ decColID ] ];
					
		// extract the namespace from the access_url column.
		var dataNamespace: string = "";
		if (accessUrl.indexOf( 'id=' ) > -1)
		{
			// get the rest of the URL.
			var remainder: string = accessUrl.slice( accessUrl.indexOf( 'id=' ) + 3 - accessUrl.length );
			if (remainder.indexOf( ':' ) > -1)
				dataNamespace = remainder.slice( 0, remainder.indexOf( ':' ) );
			else
				dataNamespace = remainder;
			
		}
		
		// download the XML file.
		fetch( accessUrl )
			.then
			(
				(response) => response.text()
			) 
			.then
			(
				(responseText) =>
				{

					// parse the XML.
					const parser = new DOMParser();
					var xml: any = parser.parseFromString( responseText, 'text/xml' );
					
					// move to VOTABLE.
					const ivoID: string = "";
					var node: any = undefined;
					var idx: number = -1;
					[node, idx] = this.findChild( {node: xml, nodeName: "VOTABLE"} );
					
					// find the SODA-sync resource child.
					var sodaSyncNode: any = undefined;
					[sodaSyncNode, idx] = this.findChild( {node: node, nodeName: "RESOURCE", attributeName: "ID", attributeValue: "soda-sync"} );
					
					// find the SODA-async resource child.
					var sodaAsyncNode: any = undefined;
					[sodaAsyncNode, idx] = this.findChild( {node: node, nodeName: "RESOURCE", attributeName: "ID", attributeValue: "soda-async"} );
					
					// move to RESOURCE -> TABLE.
					[node, idx] = this.findChild( {node: node, nodeName: "RESOURCE"} );
					[node, idx] = this.findChild( {node: node, nodeName: "TABLE"} );
					
					// find the child node with a nodeName of FIELD and an attribute 'name' = 'ID'.
					var idNode: any = undefined;
					var idIndex: number = -1;
					[idNode, idIndex] = this.findChild( {node: node, nodeName: "FIELD", attributeName: "name", attributeValue: "ID"} );
					
					// move to DATA -> TABLEDATA -> TR.
					[node, idx] = this.findChild( {node: node, nodeName: "DATA"} );
					[node, idx] = this.findChild( {node: node, nodeName: "TABLEDATA"} );
					[node, idx] = this.findChild( {node: node, nodeName: "TR"} );
					
					// find the nth item with node name "TD".
					[idNode, idx] = this.findChild( {node: node, nodeName: "TD", nthItem: idIndex} );
					var ivoLink: string = "";
					if (idNode !== undefined)
						ivoLink = idNode[ "textContent" ];
					
					// extract the file name from the ID column (everything after the last /).
					var filename: string = "";
					if (ivoLink.lastIndexOf( '/' ) > -1)
						filename = ivoLink.slice( ivoLink.lastIndexOf( '/' ) + 1 - ivoLink.length );
						
					// extract the Rucio namespace from the ID column (everything after the ? and before the next /).
					var namespace: string = "";
					if (ivoLink.indexOf( '?' ) > -1)
					{
						namespace = ivoLink.slice( ivoLink.indexOf( '?' ) - ivoLink.length + 1 );
						if (namespace.indexOf( '/' ) > -1)
							namespace = namespace.slice( 0, namespace.indexOf( '/' ) );
						else
							namespace = "";
					}
					
					// initiate a new data-management search.
					this.props.initiateDataManagementSearchEvent(	{
											namespace: namespace,
											filename: filename
											} );
					
					// Get the access url from the SODA-sync resource.
					accessUrl = "";
					if (sodaSyncNode !== undefined)
					{
						var accessNode: any = undefined;
						[accessNode, idx] = this.findChild( {node: sodaSyncNode, nodeName: "PARAM", attributeName: "name", attributeValue: "accessURL"} );
						if (accessNode !== undefined)
						{
							accessNode =  this.findAttribute( {node: accessNode, attributeName: "value"} );
							if (accessNode !== undefined)
								accessUrl = accessNode[ "textContent" ];
						}
					}
			
					// strip the domain from the url.
					var apiAddress: string = '';
					if (accessUrl.indexOf( '.int/' ) > -1)
						apiAddress = accessUrl.slice( accessUrl.indexOf( '.int/' ) + 4 - accessUrl.length );
					
					// exchange the data-management token for a download token.
					
					// fetch the file.
					if (accessUrl !== "" && dataNamespace !== "" && filename !== "" && ivoLink !== "")
					{
					
						// append the ivoLink to the access URL.
						accessUrl = accessUrl + "?ID=" + ivoLink;
						
						// append the circle to the access URL.
						if (ra !== '' && dec !== '')
							accessUrl = accessUrl + "&CIRCLE=" + ra + "%20" + dec + "%2060";
						this.copyToClipboard( accessUrl );
					
						/*const authorizationHeader: string = 'Authorization: Bearer ' + this.props.accessToken;
						fetch( apiAddress, { method: 'GET', headers: { 'Content-Type': 'application/fits' } } )
							.then
							(
								(response) =>
									response.blob()
									
							)
							.then
							(
								(blob) =>
								{
								
									// create blob link to download.
									const url = window.URL.createObjectURL( new Blob( [blob] ) );
									const link = document.createElement( 'a' );
									link.href = url;
									link.setAttribute( 'download', filename );

									// append to html link element page.
									document.body.appendChild( link );

									// start download.
									link.click();

									// clean up and remove the link.
									if (link.parentNode != null)
										link.parentNode.removeChild( link );
								}
							);*/
					}
					
				}
			)
    			.catch
    			(
    				(error) =>
	    			{ 
					console.log( 'error fetching the XML file: ', error ); 
				}
			); 
	
	} // downloadFile
	
	//	------------------------------------------------------------
	//
	//	Download a file from the LoTTS database.
	//
	//	------------------------------------------------------------
/*	
	downloadFileLoTTS( args: { row: number } )
	{
	
		// get the row that is to be retrieved.
		var row: any = this.state.sourceList[ args.row ];
		
		// get the result (url).
		var result: string = "";
		var resultColID: number = this.state.columns.findIndex( element => element === 'result' )
		if (resultColID > -1)
			result = row[ this.state.columns[ resultColID ] ];
			
		// have we retrieved the result field?
		if (result != '')
		{
		
			// extract filename part (everything after the last '/'.
			var filename: string = '';
			if (result.lastIndexOf( '/' ) > -1)
				filename = result.slice( result.lastIndexOf( '/' ) + 1 - result.length );
			
			// strip the domain from the url.
			var apiAddress: string = '';
			if (result.indexOf( '.nl/' ) > -1)
				apiAddress = result.slice( result.indexOf( '.nl/' ) + 3 - result.length );
		
			fetch( apiAddress, { method: 'GET', headers: { 'Content-Type': 'application/fits' } } )
				.then
				(
					(response) =>
						response.blob()
				)
				.then
				(
					(blob) =>
					{
					
						// create blob link to download.
						const url = window.URL.createObjectURL( new Blob( [blob] ) );
						const link = document.createElement( 'a' );
						link.href = url;
						link.setAttribute( 'download', filename );

						// append to html link element page.
						document.body.appendChild( link );

						// start download.
						link.click();

						// clean up and remove the link.
						if (link.parentNode != null)
							link.parentNode.removeChild( link );
					}
				);
		
		}
	
	} // downloadFileLoTTS
*/
	//	------------------------------------------------------------
	//
	//	When given an XML node, look for an attribute with a given
	//	name matching a given value.
	//
	//	------------------------------------------------------------
	
	findAttribute( args: { node: any, attributeName: string, attributeValue?: string } )
	{
						
		// loop through the elements attached to this node to see if we can find one matching the required value.
		var returnNode: any = undefined;
		for ( let i = 0; i < args.node[ "attributes" ].length; i++ )
		{
			var attribute: any = args.node[ "attributes" ][ i ];
			if (attribute[ "nodeName" ].toUpperCase() === args.attributeName.toUpperCase())
			{
				var attributeValueOK: boolean = true;
				if (args.attributeValue !== undefined)
					attributeValueOK = (attribute[ "nodeValue" ].toUpperCase() === args.attributeValue.toUpperCase());
				if (attributeValueOK === true)
					returnNode = attribute;
			}
		}
		
		return returnNode;
	
	} // findAttribute

	//	------------------------------------------------------------
	//
	//	When given an XML node, look for a child with a given node name.
	//
	//	------------------------------------------------------------
	
	findChild( args: { node: any, nodeName: string, attributeName?: string, attributeValue?: string, nthItem?: number } )
	{
	
		var childIndex: number = -1;
		var elementCount: number = -1;
		var elementID: number = -1;
		
		if (args.node !== undefined)
			args.node[ "childNodes" ].forEach
			(
				(element: any, index: number) =>
				{
					if (element[ "nodeName" ].toUpperCase() === args.nodeName.toUpperCase() && childIndex === -1)
					{
					
						// look for the required attribute.
						var attributeMissing: boolean = false;
						if (args.attributeName !== undefined && args.attributeValue !== undefined && 'attributes' in element)
						{
							attributeMissing = true;
							const attribute = this.findAttribute( {node: element, attributeName: args.attributeName,
													attributeValue: args.attributeValue} );
							if (attribute !== undefined)
								attributeMissing = false;
						}
	
						// increment the element count.				
						if (attributeMissing === false)
							elementCount = elementCount + 1;
						
						// if we specifically need the nth item, then check if that's what we've found.
						var nthItemFound: boolean = true;
						if (args.nthItem !== undefined)
							nthItemFound = (elementCount === args.nthItem);
							
						// are we happy ?
						if (attributeMissing === false && nthItemFound === true)
						{
							childIndex = index;
							elementID = elementCount;
						}
						
					}
				}
			)
		if (childIndex > -1)
			return [args.node[ "childNodes" ][ childIndex ], elementID];
		else
			return [undefined, -1];
	
	} // findChild

	//	------------------------------------------------------------
	//
	//	Handler for onClick events (buttons).
	//
	//	------------------------------------------------------------
  	
  	onClickHandlerBtn = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{
  	
  		const btn: HTMLButtonElement = event.currentTarget;

  		// if we've clicked on a button to download data.
  		if (btn.name.length > 16)
  			if (btn.name.slice( 0, 16 ) === "btnDownloadData_")
  			{
  			
  				// get the rest of the identifier, and convert to numeric.
				var rowID: string = btn.name.slice( 16 - btn.id.length );
  				let rowIDNumeric: number = -1;
  				try
  				{
  					rowIDNumeric = Number( rowID );
  				}
  				catch (e)
  				{
  				}
  				
  				// download the file.
  				if (rowIDNumeric > -1)
  					this.downloadFile( { row: rowIDNumeric } );
  			
  			}
  			
  		// if we've clicked on a button to view the data in Aladin
  		if (btn.name.length > 14)
  			if (btn.name.slice( 0, 14 ) === "btnViewAladin_")
  			{
  			
  				// get the rest of the identifier, and convert to numeric.
				var rowID: string = btn.name.slice( 14 - btn.id.length );
  				let rowIDNumeric: number = -1;
  				try
  				{
  					rowIDNumeric = Number( rowID );
  				}
  				catch (e)
  				{
  				}
  				
  				// view this data in Aladin
  				if (rowIDNumeric > -1)
  					this.viewInAladin( { row: rowIDNumeric } );
  			
  			}
  			
  	} // onClickHandlerBtn

	//	------------------------------------------------------------
	//
	//	Handler for onClick events (images).
	//
	//	------------------------------------------------------------
  	
  	onClickHandlerImg = (event: React.MouseEvent<HTMLImageElement>) =>
  	{
  	
  		const img: HTMLImageElement = event.currentTarget;
  		
  		// if we've clicked on a button to drill down further then update the drill-down table.
  		if (img.id.length > 8)
  			if (img.id.slice( 0, 8 ) === "btnSoda_")
  			{
  			
  				// get the rest of the identifier, and convert to numeric.
				var rowID: string = img.id.slice( 8 - img.id.length );
  				let rowIDNumeric: number = -1;
  				try
  				{
  					rowIDNumeric = Number( rowID );
  				}
  				catch (e)
  				{
  				}
  				
  				// download the file.
  				this.downloadFile( { row: rowIDNumeric } );
  			
  			}
  			
  	} // onClickHandlerImg

	//	------------------------------------------------------------
	//
	//	Remove the highlight on a table row when the mouse leaves the row.
	//
	//	------------------------------------------------------------
  	
  	onMouseLeave = (event: React.MouseEvent<HTMLTableRowElement>) =>
  	{
  	
  		this.props.setHighlightedID( '' );
  	
  	} // onMouseLeave

	//	------------------------------------------------------------
	//
	//	Highlight a table row when the mouse moves over that row.
	//
	//	------------------------------------------------------------
  	
  	onMouseOver = (event: React.MouseEvent<HTMLTableRowElement>, item: any) =>
  	{
  	
  		this.props.setHighlightedID( item[ 'obs_publisher_did' ] );
  	
  	} // onMouseOver

	//	------------------------------------------------------------
	//
	//	Button handler for check box onChange events.
	//
	//	------------------------------------------------------------
	
	rowCheckChange = (event: React.ChangeEvent<HTMLInputElement>) =>
	{

		const row: number = Number( event.target.id );
		const ticked: boolean = event.target.checked;
		
		// raise an onChange event.
		this.props.rowCheckedHandler( { row: row, ticked: ticked } );
				
	} // rowCheckChange

	//	------------------------------------------------------------
	//
	//	Check if the obs_publisher_did in this row is the selected
	//	one, and return the appropriate style.
	//
	//	------------------------------------------------------------
	
	tableColStyle( args:	{
				item: any,
				selectedID: { id: string, ra: number, dec: number }[],
				highlightedID: string
				} )
	{
	
		var rowIsSelected: boolean = false;
		var rowIsHighlighted: boolean = false;
		if ('obs_publisher_did' in args.item)
		{
			if (args.selectedID.length > 0)
				for ( var i = 0; i < args.selectedID.length; i++ )
					if (args.item[ 'obs_publisher_did' ] === args.selectedID[ i ].id)
						rowIsSelected = true;
			if (args.item[ 'obs_publisher_did' ] === args.highlightedID && args.highlightedID !== '')
				rowIsHighlighted = true;
		}
				
		// return something.
		if (rowIsSelected === true)
			return SearchResultsTable.TABLE_COL_SELECTED;
		else if (rowIsHighlighted === true)
			return SearchResultsTable.TABLE_COL_HIGHLIGHTED;
		else
			return SearchResultsTable.TABLE_COL;
	
	} // tableColStyle

	//	------------------------------------------------------------
	//
	//	View the position of a dataset in Aladin
	//
	//	------------------------------------------------------------
	
	viewInAladin( args: { row: number } )
	{
	
		// get the row that we wish to view.
		var row: any = this.state.sourceList[ args.row ];
			
		// get the RA and Dec.
		var ra: string = "";
		var raColID: number = this.state.columns.findIndex( element => element === 's_ra' );
		if (raColID > -1)
			ra = row[ this.state.columns[ raColID ] ];
		var dec: string = "";
		var decColID: number = this.state.columns.findIndex( element => element === 's_dec' );
		if (decColID > -1)
			dec = row[ this.state.columns[ decColID ] ];
		var obs_publisher_did: string = "";
		var obsColID: number = this.state.columns.findIndex( element => element === 'obs_publisher_did' );
		if (obsColID > -1)
			obs_publisher_did = row[ this.state.columns[ obsColID ] ];
			
		// propogate the request back upwards.
		this.props.viewInAladin( { ra: ra, dec: dec, obs_publisher_did: obs_publisher_did } );
	
	} // viewInAladin

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	A HTML component that contains the action buttons for each
	//	row.
	//
	//	------------------------------------------------------------

	Actions( args: { tableRow: object, index: number } )
	{

		/*return	(
		
			<div>
				<img className="actions-image" src={infoIcon} alt="" width="22" height="22" />
				<img className="actions-image" id={"btnSoda_" + args.row.toString()} src={scissorsIcon} alt="" width="22" height="22"
						onClick={this.onClickHandlerImg} />
			</div>}
		
			)*/
  		
  		// check if this row contains a result instance.
  		var result: string = "";
  		if ('access_url' in args.tableRow)
  			if (typeof args.tableRow[ 'access_url' ] === 'string')
  				result = args.tableRow[ 'access_url' ];
  		//if ('result' in args.tableRow)
  		//	if (typeof args.tableRow[ 'result' ] === 'string')
  		//		result = args.tableRow[ 'result' ];
  				
  		return	(
  			<div style = {{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 0px 0px 0px" }}>
				<button	name = {"btnDownloadData_" + args.index.toString()}
						type = "button"
						title = "Download data"
						className = "action-button"
						onClick = {this.onClickHandlerBtn}
						data-visible = {result !== '' ? "T" : "F"} >
					<img	src = {downloadIcon}
						alt = ""
						width = "30"
						height = "30" />
				</button>
  				{/*<div style = {{ flex: "0 0 2pt" }}></div>
				<button	name = {"btnViewAladin_" + args.index.toString()}
						type = "button"
						title = "View in Aladin Lite"
						className = "action-button"
						onClick = {this.onClickHandlerBtn} >
					<img	src = {viewAladinIcon}
						alt = ""
						width = "30"
						height = "30" />
				</button>*/}
  			</div>
  			)

	} // Actions

	//	------------------------------------------------------------
	//
	//	Constructs a list of columns.
	//
	//	------------------------------------------------------------
	
	TableColumns( )
	{
	
		return this.state.columns.map
		(
			(data: string) =>
			{
				return <col key = {data}/>
			}
		)
		
	} // TableColumns

	//	------------------------------------------------------------
	//
	//	Constructs a table header row from a list of column names.
	//
	//	------------------------------------------------------------
	
	TableHeadings()
	{
	
		return	<>
		{
			this.state.columns.map
			(
				(column: string) =>
				{
					return <th style = {SearchResultsTable.TABLE_COL} data-hidden = "true" key = {column}>{column}</th>
				}
			)
		}
		</>
	
	} // TableHeadings

	//	------------------------------------------------------------
	//
	//	A HTML component that takes a list of sources and renders
	//	them as a list of table rows.
	//
	//	------------------------------------------------------------
	
	TableRows( args:	{
				sourceList: SourceRowType[],
				startIndex: number,
				onChange: any,
				selectedRows: number[],
				selectedID: { id: string, ra: number, dec: number }[],
				highlightedID: string
				} )
	{

		try
		{
			return	<>
			{
				args.sourceList.map
				(
					(item, index) =>
					(
						<tr	className = "search-results-table-row"
							onMouseOver = {(event) => this.onMouseOver( event, item )}
							onMouseLeave = {this.onMouseLeave} >
							
							<td style = {this.tableColStyle( { item: item, selectedID: args.selectedID, highlightedID: args.highlightedID } )}>
								<input	type = "checkbox"
									onChange = {args.onChange}
									id = {(index + args.startIndex).toString()}
									checked = { args.selectedRows.findIndex( element => element === index + args.startIndex) > -1 } ></input>
							</td>
							<td style = {this.tableColStyle( { item: item, selectedID: args.selectedID, highlightedID: args.highlightedID } )}>
								{this.Actions( { tableRow: item, index: index } )}
							</td>
							{
								this.state.columns.map
								(
									(column) =>
									(
										<td style = {this.tableColStyle( { item: item, selectedID: args.selectedID, highlightedID: args.highlightedID } )}>
											{item[ column as keyof typeof item ]}
										</td>
									)
								)
							}
						</tr>
					)
				)
			}
			</>
		}
		catch (e)
		{
		
			return	(
				<></>
				)
			
		}

	} // TableRows
	
	render()
	{

		/* render a table row with this source */
		return	(
		    		
				<div className = "search-results-table-holder">
					
					<table	className = {this.state.loadingComponent === true ? "search-results-table-hide" : "search-results-table"} >
						<colgroup>
							<col key = "checkbox_col"/>
							<col key = "Actions_col"/>
							{this.TableColumns()}
						</colgroup>
						<thead>
							<tr className = "search-results-table-row-header">
								<th style = {SearchResultsTable.TABLE_COL}>
									<input type = "checkbox" ></input>
								</th>
								<th style = {SearchResultsTable.TABLE_COL}>Actions</th>
								{this.TableHeadings()}
							</tr>
						</thead>
						<tbody>
							{
								this.TableRows(	{
											sourceList: this.state.sourceList,
											startIndex: this.props.page * this.props.pageSize,
											onChange: this.rowCheckChange,
											selectedRows: (this.props.selectedRows !== undefined ? this.props.selectedRows.selectedRows : []),
											selectedID: (this.props.selectedID),
											highlightedID: this.props.highlightedID
											} )
							}
						</tbody>
					</table>
					
					<div className = {this.state.loadingComponent === true ? "search-results-table-loading" : "search-results-table-loaded"}>
						<img	className = "animated-gears"
							src = {gearsIcon}
							alt = ""
							width = "60"
							height = "60" />
						Loading
					</div>
					
				</div>
			
			)
			
	}

} // SearchResultsTable

export default withTranslation() (SearchResultsTable);
